export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    Cursor: { input: unknown; output: unknown };
    /** Date (isoformat) */
    Date: { input: string; output: string };
    /** Decimal (fixed-point) */
    Decimal: { input: string; output: string };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: unknown; output: unknown };
    UTCDateTime: { input: string; output: string };
    Upload: { input: File; output: unknown };
};

export type AiChatMessage = {
    __typename?: 'AIChatMessage';
    content: Scalars['String']['output'];
    role: Scalars['String']['output'];
    toolCalls?: Maybe<Array<ToolCall>>;
    toolResults?: Maybe<Array<ToolResult>>;
};

export type AiChatRequest = {
    __typename?: 'AIChatRequest';
    createdAt: Scalars['UTCDateTime']['output'];
    id: Scalars['ID']['output'];
    isCompleted: Scalars['Boolean']['output'];
    modelId?: Maybe<Scalars['String']['output']>;
    originatorId?: Maybe<Scalars['ID']['output']>;
    originatorType?: Maybe<Scalars['String']['output']>;
    primaryRequestMessageContent?: Maybe<Scalars['String']['output']>;
    requestMessages: Array<AiChatMessage>;
    responseMessages?: Maybe<Array<AiChatMessage>>;
    timer?: Maybe<Scalars['Float']['output']>;
    type: Scalars['String']['output'];
};

export type ActivityEvent = {
    __typename?: 'ActivityEvent';
    createdAt: Scalars['UTCDateTime']['output'];
    eventData?: Maybe<Scalars['JSON']['output']>;
    eventType: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    requestingPath?: Maybe<Scalars['String']['output']>;
    user?: Maybe<User>;
};

export type AdminAddIndexTagInput = {
    indexId: Scalars['ID']['input'];
    tagId: Scalars['ID']['input'];
};

export type AdminAddInstrumentCompRequest = {
    baseInstrumentId: Scalars['ID']['input'];
    compInstrumentIds: Scalars['String']['input'];
};

export type AdminAddInstrumentCompResult = {
    __typename?: 'AdminAddInstrumentCompResult';
    success: Scalars['Boolean']['output'];
};

export type AdminCreateIndexVersionInput = {
    effectiveAtDate: Scalars['Date']['input'];
    indexId: Scalars['ID']['input'];
    strategyBlob: Scalars['String']['input'];
};

export type AdminCreateIndexVersionsFromSheetInput = {
    addRemoveBlob: Scalars['String']['input'];
    indexId: Scalars['ID']['input'];
    weightStrategy: Scalars['String']['input'];
};

export type AdminCreateIndexVersionsFromSheetResult = {
    __typename?: 'AdminCreateIndexVersionsFromSheetResult';
    success: Scalars['Boolean']['output'];
    versions: Array<IndexVersion>;
};

export type AdminCreateInstrumentDataManualActiveRuleInput = {
    endDate?: InputMaybe<Scalars['Date']['input']>;
    instrumentId: Scalars['ID']['input'];
    isActive: Scalars['Boolean']['input'];
    startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type AdminCreateInstrumentIdentifierExchangeSymbolInput = {
    exchange: Scalars['String']['input'];
    symbol: Scalars['String']['input'];
};

export type AdminCreateInstrumentIdentifierInput = {
    exchangeSymbol?: InputMaybe<AdminCreateInstrumentIdentifierExchangeSymbolInput>;
    instrumentId: Scalars['ID']['input'];
    setAsPrimary?: Scalars['Boolean']['input'];
};

export type AdminCreateOrganizationInput = {
    name: Scalars['String']['input'];
    twitterHandle?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreateTagInput = {
    categoryName: Scalars['String']['input'];
    tagName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminDateWindowStatsType = {
    __typename?: 'AdminDateWindowStatsType';
    asOf: Scalars['UTCDateTime']['output'];
    dateWindowType: AdminDateWindowType;
    endAt: Scalars['UTCDateTime']['output'];
    startAt?: Maybe<Scalars['UTCDateTime']['output']>;
    stats: AdminStatsType;
};

export enum AdminDateWindowType {
    AllTime = 'ALL_TIME',
    CurrentMonth = 'CURRENT_MONTH',
    CurrentQuarter = 'CURRENT_QUARTER',
    CurrentWeek = 'CURRENT_WEEK',
    LastMonth = 'LAST_MONTH',
    LastQuarter = 'LAST_QUARTER',
    LastWeek = 'LAST_WEEK',
    YearToDate = 'YEAR_TO_DATE',
}

export type AdminDeleteIndexVersionInput = {
    indexVersionId: Scalars['ID']['input'];
};

export type AdminDeleteIndexVersionResult = {
    __typename?: 'AdminDeleteIndexVersionResult';
    success: Scalars['Boolean']['output'];
};

export type AdminDisableIndexProcessingForOrganization = {
    organizationId: Scalars['ID']['input'];
};

export type AdminDisableIndexProcessingForOrganizationResult = {
    __typename?: 'AdminDisableIndexProcessingForOrganizationResult';
    success: Scalars['Boolean']['output'];
};

export type AdminGetInstrumentsInput = {
    fuzzyIdentifiers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminLimitOrganizationAdmins = {
    organizationId: Scalars['ID']['input'];
};

export type AdminMutation = {
    __typename?: 'AdminMutation';
    addIndexTag: Scalars['String']['output'];
    addInstrumentEvaluationComp: AdminAddInstrumentCompResult;
    analystOpenQuery: AnalystOpenQueryResult;
    archiveProject: Scalars['Boolean']['output'];
    clearInstrumentData: ClearInstrumentDataResult;
    createActivityEvent: ActivityEvent;
    createBenchmarkFromInstrument: BenchmarkIndex;
    createBenchmarkIndexFromThematicIndex: BenchmarkIndex;
    createIndex: CreateIndexResult;
    createIndexVersion: IndexVersion;
    createIndexVersionsFromSheet: AdminCreateIndexVersionsFromSheetResult;
    createInstrumentDataManualActiveRule: InstrumentDataManualActiveRule;
    createInstrumentIdentifier: InstrumentIdentifier;
    createInstrumentSearch: InstrumentSearch;
    createLoginAttempt: SendLoginAttemptResult;
    createOrAddUserToOrganization: Scalars['Boolean']['output'];
    createOrUpdateIndexDraft: CreateOrUpdateIndexDraftResult;
    createOrganization: Organization;
    createOrganizationUserInvite: CreateOrganizationUserInviteResult;
    createPerformanceComparison: PerformanceComparison;
    createProject: CreateProjectResult;
    createResearchNote: CreateResearchNoteResult;
    createTag: Tag;
    createUserAndOrganization: CreateUserResult;
    createUserSession: CreateUserSessionResult;
    deleteIndexVersion: AdminDeleteIndexVersionResult;
    deleteInstrumentDataManualActiveRule: InstrumentDataManualActiveRule;
    deleteInstrumentIdentifier: InstrumentIdentifier;
    deleteTag: Tag;
    deleteUserAvatarImage: DeleteUserAvatarImageResult;
    disableIndexProcessingForOrganization: AdminDisableIndexProcessingForOrganizationResult;
    followIndex: FollowIndexResult;
    generateOrganizationApiAuthToken: Organization;
    inferInstrumentFilter: InstrumentFilter;
    inferInstrumentSearchFilters: InferredInstrumentSearchFiltersResult;
    initiateProcessIndex: Index;
    instrumentArticle: InstrumentArticle;
    limitOrganizationToOneAdmin: User;
    logOutUserSession: LogOutUserSessionResult;
    markDistributionInactive: InstrumentDataDistribution;
    notifyForInstrumentEvaluationRequest: AdminNotifyForInstrumentEvaluationRequestResult;
    purgeIndexSnapshots: AdminPurgeIndexSnapshotsResult;
    removeIndexTag: Scalars['String']['output'];
    removeInstrumentEvaluationComp: AdminRemoveInstrumentCompResult;
    removeOrganizationUser: RemoveOrganizationUserResult;
    removeProject: Scalars['Boolean']['output'];
    removeResearchNote: Scalars['Boolean']['output'];
    resendLoginAttempt: SendLoginAttemptResult;
    resendOrganizationUserInvite: ResendOrganizationUserInviteResult;
    setInstrumentEvaluationComps: SetInstrumentCompsResult;
    setOrganizationAccountTier: AdminSetOrganizationAccountTierResult;
    setOrganizationCompanyEvaluationLimit: AdminSetOrganizationCompanyEvaluationLimitResult;
    setQuarterlyRebalanceSchedule: Index;
    setUserIsThematicAdmin: User;
    setUserOrganization: User;
    unfollowIndex: FollowIndexResult;
    updateIndex: AdminUpdateIndexResult;
    updateIndexBannerImage: UpdateIndexBannerImageResult;
    updateIndexIsComponentsPrivate: Index;
    updateIndexIsHidden: Index;
    updateOrganization: AdminUpdateOrganizationResult;
    updateOrganizationAvatarImage: UpdateOrganizationAvatarImageResult;
    updateOrganizationProfile: UpdateOrganizationProfileResult;
    updateOrganizationUserRole: OrganizationUser;
    updateProject: UpdateProjectResult;
    updateResearchNote: UpdateResearchNoteResult;
    updateTagBannerImage: Tag;
    updateTagDescription: Tag;
    updateUserAvatarImage: UpdateUserAvatarImageResult;
    updateUserProfile: UpdateUserProfileResult;
    uploadIndexOpengraphImage: UploadIndexOpengraphImageResult;
};

export type AdminMutationAddIndexTagArgs = {
    input: AdminAddIndexTagInput;
};

export type AdminMutationAddInstrumentEvaluationCompArgs = {
    request: AdminAddInstrumentCompRequest;
};

export type AdminMutationAnalystOpenQueryArgs = {
    query: Scalars['String']['input'];
};

export type AdminMutationArchiveProjectArgs = {
    input: ArchiveProjectInput;
};

export type AdminMutationClearInstrumentDataArgs = {
    andReprocessIndexes?: Scalars['Boolean']['input'];
    instrumentId: Scalars['ID']['input'];
};

export type AdminMutationCreateActivityEventArgs = {
    eventData?: InputMaybe<Scalars['JSON']['input']>;
    eventType: Scalars['String']['input'];
    requestingPath?: InputMaybe<Scalars['String']['input']>;
};

export type AdminMutationCreateBenchmarkFromInstrumentArgs = {
    input: CreateBenchmarkInstrumentInput;
};

export type AdminMutationCreateBenchmarkIndexFromThematicIndexArgs = {
    input: CreateThematicIndexBenchmarkIndexInput;
};

export type AdminMutationCreateIndexArgs = {
    input: CreateIndexInputType;
};

export type AdminMutationCreateIndexVersionArgs = {
    input: AdminCreateIndexVersionInput;
};

export type AdminMutationCreateIndexVersionsFromSheetArgs = {
    input: AdminCreateIndexVersionsFromSheetInput;
};

export type AdminMutationCreateInstrumentDataManualActiveRuleArgs = {
    input: AdminCreateInstrumentDataManualActiveRuleInput;
};

export type AdminMutationCreateInstrumentIdentifierArgs = {
    input: AdminCreateInstrumentIdentifierInput;
};

export type AdminMutationCreateInstrumentSearchArgs = {
    input: CreateInstrumentSearchInput;
};

export type AdminMutationCreateLoginAttemptArgs = {
    input: CreateLoginAttemptInput;
};

export type AdminMutationCreateOrAddUserToOrganizationArgs = {
    input: CreateOrAddUserToOrganizationInput;
};

export type AdminMutationCreateOrUpdateIndexDraftArgs = {
    input: CreateOrUpdateIndexDraftInput;
};

export type AdminMutationCreateOrganizationArgs = {
    input: AdminCreateOrganizationInput;
};

export type AdminMutationCreateOrganizationUserInviteArgs = {
    input: CreateOrganizationUserInviteInput;
};

export type AdminMutationCreatePerformanceComparisonArgs = {
    input: PerformanceComparisonInput;
};

export type AdminMutationCreateProjectArgs = {
    input: CreateProjectInputType;
};

export type AdminMutationCreateResearchNoteArgs = {
    input: CreateResearchNoteInputType;
};

export type AdminMutationCreateTagArgs = {
    input: AdminCreateTagInput;
};

export type AdminMutationCreateUserAndOrganizationArgs = {
    input: CreateUserAndOrganizationInput;
};

export type AdminMutationCreateUserSessionArgs = {
    input: CreateUserSessionInput;
};

export type AdminMutationDeleteIndexVersionArgs = {
    input: AdminDeleteIndexVersionInput;
};

export type AdminMutationDeleteInstrumentDataManualActiveRuleArgs = {
    id: Scalars['ID']['input'];
};

export type AdminMutationDeleteInstrumentIdentifierArgs = {
    instrumentIdentifierId: Scalars['ID']['input'];
};

export type AdminMutationDeleteTagArgs = {
    id: Scalars['ID']['input'];
};

export type AdminMutationDeleteUserAvatarImageArgs = {
    userId: Scalars['ID']['input'];
};

export type AdminMutationDisableIndexProcessingForOrganizationArgs = {
    input: AdminDisableIndexProcessingForOrganization;
};

export type AdminMutationFollowIndexArgs = {
    input: FollowIndexInput;
};

export type AdminMutationGenerateOrganizationApiAuthTokenArgs = {
    organizationId: Scalars['ID']['input'];
};

export type AdminMutationInferInstrumentFilterArgs = {
    query: Scalars['String']['input'];
};

export type AdminMutationInferInstrumentSearchFiltersArgs = {
    query: Scalars['String']['input'];
};

export type AdminMutationInitiateProcessIndexArgs = {
    indexId: Scalars['ID']['input'];
};

export type AdminMutationInstrumentArticleArgs = {
    id: Scalars['ID']['input'];
};

export type AdminMutationLimitOrganizationToOneAdminArgs = {
    input: AdminLimitOrganizationAdmins;
};

export type AdminMutationMarkDistributionInactiveArgs = {
    eventIdentifier: Scalars['String']['input'];
};

export type AdminMutationNotifyForInstrumentEvaluationRequestArgs = {
    forceNotify?: InputMaybe<Scalars['Boolean']['input']>;
    id: Scalars['ID']['input'];
};

export type AdminMutationPurgeIndexSnapshotsArgs = {
    input: AdminPurgeIndexSnapshotsInput;
};

export type AdminMutationRemoveIndexTagArgs = {
    input: AdminRemoveIndexTagInput;
};

export type AdminMutationRemoveInstrumentEvaluationCompArgs = {
    request: AdminRemoveInstrumentCompRequest;
};

export type AdminMutationRemoveOrganizationUserArgs = {
    input: RemoveOrganizationUserInput;
};

export type AdminMutationRemoveProjectArgs = {
    input: RemoveProjectInput;
};

export type AdminMutationRemoveResearchNoteArgs = {
    input: RemoveResearchNoteInput;
};

export type AdminMutationResendLoginAttemptArgs = {
    input: ResendLoginAttemptInput;
};

export type AdminMutationResendOrganizationUserInviteArgs = {
    input: ResendOrganizationUserInviteInput;
};

export type AdminMutationSetInstrumentEvaluationCompsArgs = {
    request: SetInstrumentCompsRequest;
};

export type AdminMutationSetOrganizationAccountTierArgs = {
    input: AdminSetOrganizationAccountTier;
};

export type AdminMutationSetOrganizationCompanyEvaluationLimitArgs = {
    input: AdminSetOrganizationCompanyEvaluationLimit;
};

export type AdminMutationSetQuarterlyRebalanceScheduleArgs = {
    input: SetQuarterlyRebalanceScheduleInput;
};

export type AdminMutationSetUserIsThematicAdminArgs = {
    input: AdminSetUserIsThematicAdminInput;
};

export type AdminMutationSetUserOrganizationArgs = {
    input: AdminSetUserOrganizationInput;
};

export type AdminMutationUnfollowIndexArgs = {
    input: FollowIndexInput;
};

export type AdminMutationUpdateIndexArgs = {
    input: AdminUpdateIndexInput;
};

export type AdminMutationUpdateIndexBannerImageArgs = {
    input: UpdateIndexBannerImageInput;
};

export type AdminMutationUpdateIndexIsComponentsPrivateArgs = {
    input: UpdateIndexIsComponentsPrivateType;
};

export type AdminMutationUpdateIndexIsHiddenArgs = {
    input: UpdateIndexIsHiddenType;
};

export type AdminMutationUpdateOrganizationArgs = {
    input: AdminUpdateOrganizationInput;
};

export type AdminMutationUpdateOrganizationAvatarImageArgs = {
    input: UpdateOrganizationAvatarImageInput;
};

export type AdminMutationUpdateOrganizationProfileArgs = {
    input: UpdateOrganizationProfileInput;
};

export type AdminMutationUpdateOrganizationUserRoleArgs = {
    input: UpdateOrganizationUserRoleInput;
};

export type AdminMutationUpdateProjectArgs = {
    input: UpdateProjectInput;
};

export type AdminMutationUpdateResearchNoteArgs = {
    input: UpdateResearchNoteInput;
};

export type AdminMutationUpdateTagBannerImageArgs = {
    id: Scalars['ID']['input'];
    imageFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type AdminMutationUpdateTagDescriptionArgs = {
    description?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
};

export type AdminMutationUpdateUserAvatarImageArgs = {
    input: UpdateUserAvatarImageInput;
};

export type AdminMutationUpdateUserProfileArgs = {
    input: UpdateUserProfileInput;
};

export type AdminMutationUploadIndexOpengraphImageArgs = {
    input: UploadIndexOpengraphImageInput;
};

export type AdminNotifyForInstrumentEvaluationRequestResult = {
    __typename?: 'AdminNotifyForInstrumentEvaluationRequestResult';
    success: Scalars['Boolean']['output'];
};

export type AdminPurgeIndexSnapshotsInput = {
    indexId: Scalars['ID']['input'];
    minAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    reprocessIndex?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminPurgeIndexSnapshotsResult = {
    __typename?: 'AdminPurgeIndexSnapshotsResult';
    success: Scalars['Boolean']['output'];
};

export type AdminQuery = {
    __typename?: 'AdminQuery';
    activityEvents: Array<ActivityEvent>;
    adminInstrumentEvaluationRequest: Scalars['String']['output'];
    adminInstruments: Array<Instrument>;
    adminStandardDateWindowStats: Array<AdminDateWindowStatsType>;
    adminStats: AdminStatsType;
    aiChatRequests: Array<AiChatRequest>;
    allowInstrumentEvaluationRequest: Scalars['Boolean']['output'];
    analystOpenRequest?: Maybe<AnalystOpenRequest>;
    analystOpenRequests: Array<AnalystOpenRequest>;
    analystThemeRequest?: Maybe<AnalystThemeRequest>;
    analystThemeRequests: Array<AnalystThemeRequest>;
    analystThemeResult: AnalystThemeResult;
    analystThemeSearch: AnalystThemeResult;
    articleStrategy: Array<WeightFromArticle>;
    benchmarkIndexes: Array<BenchmarkIndex>;
    currentUser?: Maybe<User>;
    factsetCorporateActions: Array<FgpCaEvent>;
    factsetPrices: Array<FgpGlobalPrice>;
    featuredIndex?: Maybe<Index>;
    index?: Maybe<Index>;
    indexDraft?: Maybe<IndexDraft>;
    indexVersion?: Maybe<IndexVersion>;
    indexes: Array<Index>;
    instrument?: Maybe<Instrument>;
    instrumentAdjustedPrices: Array<InstrumentAdjustedPriceType>;
    instrumentAnalysis: InstrumentAnalysis;
    instrumentAnalysisRequests: Array<InstrumentAnalysis>;
    instrumentArticleCounts: Array<InstrumentArticleCount>;
    instrumentComps: Array<Instrument>;
    instrumentData: InstrumentData;
    instrumentDataManualActiveRules: Array<InstrumentDataManualActiveRule>;
    instrumentEvaluationAllComps: Array<InstrumentEvaluationComp>;
    instrumentEvaluationRequest: Scalars['String']['output'];
    instrumentEvaluationRequestCount: Scalars['Int']['output'];
    instrumentEvaluationRequests: Array<InstrumentEvaluationRequest>;
    instrumentEvaluationRequestsRemaining: Scalars['Int']['output'];
    instrumentEvaluationResult: InstrumentEvaluation;
    instrumentLookupBySymbol: Array<Instrument>;
    instrumentNews: InstrumentNews;
    instrumentNewsStats: Array<InstrumentNewsStats>;
    instrumentSearch: InstrumentSearchResults;
    instruments: Array<Instrument>;
    instrumentsVolatility: InstrumentsVolatilityResult;
    /** @deprecated Use aiChatRequests */
    openaiChatRequests: Array<AiChatRequest>;
    organization?: Maybe<Organization>;
    organizationPerformanceCsv: Scalars['String']['output'];
    organizationUserInvite?: Maybe<OrganizationUserInvite>;
    organizations: Array<Organization>;
    projects: Array<Project>;
    rankedIndexes: RankedIndexesResultType;
    researchNote?: Maybe<ResearchNote>;
    researchNotes: Array<ResearchNote>;
    sectorsAndIndustries: Array<InstrumentSector>;
    systemVariables: Array<SystemVariable>;
    tag?: Maybe<Tag>;
    tags: Array<Tag>;
    user?: Maybe<User>;
    userPerformanceCsv: Scalars['String']['output'];
    userSessions: Array<UserSession>;
    users: Array<User>;
    validActivityEventTypes: Array<Scalars['String']['output']>;
};

export type AdminQueryActivityEventsArgs = {
    eventTypes?: InputMaybe<Array<Scalars['String']['input']>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryAdminInstrumentEvaluationRequestArgs = {
    forceNew?: Scalars['Boolean']['input'];
    instrumentId: Scalars['ID']['input'];
};

export type AdminQueryAdminInstrumentsArgs = {
    input: AdminGetInstrumentsInput;
};

export type AdminQueryAdminStandardDateWindowStatsArgs = {
    asOfDate?: InputMaybe<Scalars['Date']['input']>;
};

export type AdminQueryAdminStatsArgs = {
    pastNumDays?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryAiChatRequestsArgs = {
    limit: Scalars['Int']['input'];
};

export type AdminQueryAnalystOpenRequestArgs = {
    id: Scalars['ID']['input'];
};

export type AdminQueryAnalystOpenRequestsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryAnalystThemeRequestArgs = {
    id: Scalars['ID']['input'];
};

export type AdminQueryAnalystThemeRequestsArgs = {
    excludeThematicAdmins?: Scalars['Boolean']['input'];
    includeAnalystGoldenPathRequests?: Scalars['Boolean']['input'];
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryAnalystThemeResultArgs = {
    requestId: Scalars['ID']['input'];
};

export type AdminQueryAnalystThemeSearchArgs = {
    userFilter?: InputMaybe<InstrumentSearchFilterInput>;
    userQuery: Scalars['String']['input'];
};

export type AdminQueryArticleStrategyArgs = {
    id: Scalars['ID']['input'];
};

export type AdminQueryFactsetCorporateActionsArgs = {
    fsymId: Scalars['ID']['input'];
};

export type AdminQueryFactsetPricesArgs = {
    fsymId: Scalars['ID']['input'];
    limit: Scalars['Int']['input'];
    offset: Scalars['Int']['input'];
};

export type AdminQueryIndexArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    symbol?: InputMaybe<Scalars['String']['input']>;
};

export type AdminQueryIndexDraftArgs = {
    builderIndexId: Scalars['ID']['input'];
};

export type AdminQueryIndexVersionArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminQueryIndexesArgs = {
    hasSnapshotsOnly?: InputMaybe<Scalars['Boolean']['input']>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    isBloombergEnabledOnly?: InputMaybe<Scalars['Boolean']['input']>;
    isNotable?: InputMaybe<Scalars['Boolean']['input']>;
    isProcessingDisabledOnly?: InputMaybe<Scalars['Boolean']['input']>;
    symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminQueryInstrumentArgs = {
    id: Scalars['ID']['input'];
};

export type AdminQueryInstrumentAdjustedPricesArgs = {
    instrumentId: Scalars['ID']['input'];
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryInstrumentAnalysisArgs = {
    instrumentId: Scalars['ID']['input'];
    userQuery: Scalars['String']['input'];
};

export type AdminQueryInstrumentAnalysisRequestsArgs = {
    excludeThematicAdmins?: InputMaybe<Scalars['Boolean']['input']>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryInstrumentCompsArgs = {
    instrumentId: Scalars['ID']['input'];
};

export type AdminQueryInstrumentDataArgs = {
    instrumentId: Scalars['ID']['input'];
};

export type AdminQueryInstrumentDataManualActiveRulesArgs = {
    createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    instrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AdminQueryInstrumentEvaluationAllCompsArgs = {
    baseInstrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    forOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AdminQueryInstrumentEvaluationRequestArgs = {
    instrumentId: Scalars['ID']['input'];
};

export type AdminQueryInstrumentEvaluationRequestsArgs = {
    createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    instrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AdminQueryInstrumentEvaluationResultArgs = {
    requestId: Scalars['ID']['input'];
};

export type AdminQueryInstrumentLookupBySymbolArgs = {
    input: InstrumentSearchBySymbol;
};

export type AdminQueryInstrumentNewsArgs = {
    instrumentId: Scalars['ID']['input'];
};

export type AdminQueryInstrumentSearchArgs = {
    input: InstrumentSearchInput;
};

export type AdminQueryInstrumentsArgs = {
    ids: Array<Scalars['ID']['input']>;
};

export type AdminQueryInstrumentsVolatilityArgs = {
    input: InstrumentsByVolatilityScoreInput;
};

export type AdminQueryOpenaiChatRequestsArgs = {
    limit: Scalars['Int']['input'];
};

export type AdminQueryOrganizationArgs = {
    id: Scalars['ID']['input'];
};

export type AdminQueryOrganizationPerformanceCsvArgs = {
    organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminQueryOrganizationUserInviteArgs = {
    id: Scalars['ID']['input'];
    pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminQueryProjectsArgs = {
    createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminQueryRankedIndexesArgs = {
    bottomCount?: InputMaybe<Scalars['Int']['input']>;
    minAsOfDate: Scalars['Date']['input'];
    minComponentCount?: InputMaybe<Scalars['Int']['input']>;
    topCount?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminQueryResearchNoteArgs = {
    id: Scalars['ID']['input'];
};

export type AdminQueryResearchNotesArgs = {
    allAnalystRequestIds?: InputMaybe<Scalars['Boolean']['input']>;
    allEvaluationRequestIds?: InputMaybe<Scalars['Boolean']['input']>;
    allIndexIds?: InputMaybe<Scalars['Boolean']['input']>;
    allInstrumentIds?: InputMaybe<Scalars['Boolean']['input']>;
    analystRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    evaluationRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    indexIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    instrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    organizationId?: InputMaybe<Scalars['ID']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminQueryTagArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export type AdminQueryUserArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminQueryUserPerformanceCsvArgs = {
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminQueryUserSessionsArgs = {
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminRemoveIndexTagInput = {
    indexId: Scalars['ID']['input'];
    tagId: Scalars['ID']['input'];
};

export type AdminRemoveInstrumentCompRequest = {
    baseInstrumentId: Scalars['ID']['input'];
    compInstrumentId: Scalars['ID']['input'];
};

export type AdminRemoveInstrumentCompResult = {
    __typename?: 'AdminRemoveInstrumentCompResult';
    success: Scalars['Boolean']['output'];
};

export type AdminSetOrganizationAccountTier = {
    accountTier: Scalars['String']['input'];
    organizationId: Scalars['ID']['input'];
};

export type AdminSetOrganizationAccountTierResult = {
    __typename?: 'AdminSetOrganizationAccountTierResult';
    success: Scalars['Boolean']['output'];
};

export type AdminSetOrganizationCompanyEvaluationLimit = {
    newEvaluationLimit: Scalars['Int']['input'];
    organizationId: Scalars['ID']['input'];
};

export type AdminSetOrganizationCompanyEvaluationLimitResult = {
    __typename?: 'AdminSetOrganizationCompanyEvaluationLimitResult';
    success: Scalars['Boolean']['output'];
};

export type AdminSetUserIsThematicAdminInput = {
    isThematicAdmin: Scalars['Boolean']['input'];
    userId: Scalars['ID']['input'];
};

export type AdminSetUserOrganizationInput = {
    organizationId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type AdminStatsType = {
    __typename?: 'AdminStatsType';
    endAt?: Maybe<Scalars['UTCDateTime']['output']>;
    numIndexesFromAnalyst: Scalars['Int']['output'];
    numOrganizationsWithIndexes: Scalars['Int']['output'];
    numQualityIndexes: Scalars['Int']['output'];
    numTotalAnalystThemeRequests: Scalars['Int']['output'];
    numTotalIndexes: Scalars['Int']['output'];
    numTotalOrganizations: Scalars['Int']['output'];
    numTotalUsers: Scalars['Int']['output'];
    startAt?: Maybe<Scalars['UTCDateTime']['output']>;
};

export type AdminUpdateIndexInput = {
    baseValue?: InputMaybe<Scalars['Decimal']['input']>;
    currencyCode?: InputMaybe<Scalars['String']['input']>;
    indexId: Scalars['ID']['input'];
    isBloombergEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isComponentsPrivate?: InputMaybe<Scalars['Boolean']['input']>;
    isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
    isHidden?: InputMaybe<Scalars['Boolean']['input']>;
    isInverse?: InputMaybe<Scalars['Boolean']['input']>;
    isLocked?: InputMaybe<Scalars['Boolean']['input']>;
    isNotable?: InputMaybe<Scalars['Boolean']['input']>;
    isProcessingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
    isPromotionDisabled?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    symbol?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateIndexResult = Error | Index;

export type AdminUpdateOrganizationInput = {
    canUseCryptoInIndexBuilder?: InputMaybe<Scalars['Boolean']['input']>;
    organizationId: Scalars['ID']['input'];
};

export type AdminUpdateOrganizationResult = Organization;

export type AnalysisFigures = {
    __typename?: 'AnalysisFigures';
    annualRollingVolatility: Scalars['Decimal']['output'];
    drawdown: Scalars['Decimal']['output'];
    priceToBook: Scalars['Decimal']['output'];
    priceToEarnings: Scalars['Decimal']['output'];
    priceToSales: Scalars['Decimal']['output'];
    sharpeRatio?: Maybe<Scalars['Decimal']['output']>;
    stdDev: Scalars['Decimal']['output'];
};

export type AnalystInstrumentAnalysisQuery = {
    __typename?: 'AnalystInstrumentAnalysisQuery';
    instrument: Instrument;
    query: Scalars['String']['output'];
};

export type AnalystOpenQueryResult = AnalystInstrumentAnalysisQuery | AnalystThemeResult;

export type AnalystOpenRequest = {
    __typename?: 'AnalystOpenRequest';
    aiChatRequests: Array<AiChatRequest>;
    createdAt: Scalars['UTCDateTime']['output'];
    id: Scalars['ID']['output'];
    isThemeRequest: Scalars['Boolean']['output'];
    resultAnalysisInstrument?: Maybe<Instrument>;
    user: User;
    userQuery: Scalars['String']['output'];
};

export type AnalystThemeRequest = {
    __typename?: 'AnalystThemeRequest';
    aiChatRequests: Array<AiChatRequest>;
    appliedFilter?: Maybe<Scalars['JSON']['output']>;
    appliedFilterAsString?: Maybe<Scalars['String']['output']>;
    asThemeResult: AnalystThemeResult;
    createdAt: Scalars['UTCDateTime']['output'];
    deviceId?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    inferenceFailedToParseFilters?: Maybe<Array<Scalars['JSON']['output']>>;
    inferredFieldSorts?: Maybe<Array<FieldSort>>;
    inferredFieldsOfInterest?: Maybe<Array<Scalars['String']['output']>>;
    inferredFilter?: Maybe<Scalars['JSON']['output']>;
    inferredFilterAsString?: Maybe<Scalars['String']['output']>;
    instrumentMatchResults?: Maybe<Array<ThemeInstrumentMatchResult>>;
    isAnalysisCompleted: Scalars['Boolean']['output'];
    isCompleted: Scalars['Boolean']['output'];
    matchResultLimit?: Maybe<Scalars['Int']['output']>;
    numAppliedSearchResults?: Maybe<Scalars['Int']['output']>;
    numEmbeddingsSimilarityResults?: Maybe<Scalars['Int']['output']>;
    themeAnalysis?: Maybe<Scalars['String']['output']>;
    themeName?: Maybe<Scalars['String']['output']>;
    themeQuery?: Maybe<Scalars['String']['output']>;
    timer?: Maybe<Scalars['Float']['output']>;
    user?: Maybe<User>;
    userFilter?: Maybe<Scalars['JSON']['output']>;
    userFilterAsString?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['ID']['output']>;
    userQuery: Scalars['String']['output'];
};

export type AnalystThemeResult = {
    __typename?: 'AnalystThemeResult';
    analysis?: Maybe<Scalars['String']['output']>;
    analysisSubcategories?: Maybe<Scalars['String']['output']>;
    analysisSummary?: Maybe<Scalars['String']['output']>;
    fieldsOfInterest?: Maybe<Array<Scalars['String']['output']>>;
    filter?: Maybe<Scalars['JSON']['output']>;
    filterFields?: Maybe<Array<Field>>;
    inferredReferenceInstruments?: Maybe<Array<InferredReferenceInstrument>>;
    instrumentResults?: Maybe<Array<ThemeInstrumentMatchResultBase>>;
    isAnalysisCompleted: Scalars['Boolean']['output'];
    isCompleted: Scalars['Boolean']['output'];
    isInstrumentMatchesFullyEvaluated: Scalars['Boolean']['output'];
    isLimited: Scalars['Boolean']['output'];
    isStockScreener: Scalars['Boolean']['output'];
    name?: Maybe<Scalars['String']['output']>;
    processingMessage?: Maybe<Scalars['String']['output']>;
    progressPercentage: Scalars['Int']['output'];
    requestId: Scalars['ID']['output'];
    theme?: Maybe<Scalars['String']['output']>;
    topMatchResult?: Maybe<ThemeInstrumentMatchResult>;
    userQuery: Scalars['String']['output'];
};

export type ArchiveProjectInput = {
    projectId: Scalars['ID']['input'];
};

export type BacktestInput = {
    name: Scalars['String']['input'];
    strategy: WeightStrategyInput;
};

export type BacktestStrategyResult = PerformanceComparisonResult & {
    __typename?: 'BacktestStrategyResult';
    analysis?: Maybe<AnalysisFigures>;
    name: Scalars['String']['output'];
    points: Array<DatedValue>;
    strategy: BaseStrategy;
    strategy_: BaseStrategy;
    timer?: Maybe<Scalars['Float']['output']>;
};

export type BaseStrategy = {
    emptyField: Scalars['String']['output'];
};

export type BenchmarkIndex = {
    __typename?: 'BenchmarkIndex';
    backedBy?: Maybe<BenchmarkIndexBackedBy>;
    createdAt: Scalars['UTCDateTime']['output'];
    id: Scalars['ID']['output'];
    indexes: Array<Index>;
    name: Scalars['String']['output'];
    numIndexes: Scalars['Int']['output'];
    symbol: Scalars['String']['output'];
};

export type BenchmarkIndexBackedBy = Index | Instrument;

export type ClearInstrumentDataResult = {
    __typename?: 'ClearInstrumentDataResult';
    instrument: Instrument;
    purgedIndexes: Array<Index>;
};

export enum CompanyEvaluationModuleType {
    ComparativeAnalysis = 'COMPARATIVE_ANALYSIS',
    EarningsCall = 'EARNINGS_CALL',
    EvaluationSummary = 'EVALUATION_SUMMARY',
    Filings = 'FILINGS',
    FinancialStatements = 'FINANCIAL_STATEMENTS',
    Fundamentals = 'FUNDAMENTALS',
    KeyTakeaways = 'KEY_TAKEAWAYS',
    News = 'NEWS',
    PeerFundamentals = 'PEER_FUNDAMENTALS',
    PriceHistory = 'PRICE_HISTORY',
}

export enum CompanyInfoSectionGroupKeys {
    All = 'ALL',
    CompanyAnalysis = 'COMPANY_ANALYSIS',
    CompanyPerformance = 'COMPANY_PERFORMANCE',
    Description = 'DESCRIPTION',
    Documents = 'DOCUMENTS',
    Metrics = 'METRICS',
}

export enum Comparator {
    Gt = 'GT',
    Gte = 'GTE',
    Lt = 'LT',
    Lte = 'LTE',
}

export type CreateBenchmarkInstrumentInput = {
    instrumentId: Scalars['ID']['input'];
};

export type CreateIndexInputType = {
    baseDate?: Scalars['Date']['input'];
    baseValue?: Scalars['Decimal']['input'];
    benchmarkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    currencyCode?: Scalars['String']['input'];
    description: Scalars['String']['input'];
    isHidden: Scalars['Boolean']['input'];
    isPortfolio: Scalars['Boolean']['input'];
    longDescription?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    notifyAfterLastRebalanceDays?: InputMaybe<Scalars['Int']['input']>;
    notifyBeforeScheduledRebalanceDays?: InputMaybe<Scalars['Int']['input']>;
    organizationId: Scalars['ID']['input'];
    originatingAnalystThemeRequestId?: InputMaybe<Scalars['ID']['input']>;
    symbol?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIndexResult = FieldErrors | Index;

export type CreateIndexVersionInput = {
    andPublish?: InputMaybe<Scalars['Boolean']['input']>;
    baseIndexVersionId?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    indexBenchmarkIndexIds: Array<Scalars['ID']['input']>;
    indexDescription: Scalars['String']['input'];
    indexId: Scalars['ID']['input'];
    indexIsHidden?: InputMaybe<Scalars['Boolean']['input']>;
    indexIsPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
    indexName: Scalars['String']['input'];
    name: Scalars['String']['input'];
    weightStrategy: WeightStrategyInput;
    withQuarterlyRebalanceSchedule?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateIndexVersionResult = FieldErrors | IndexVersion;

export type CreateInstrumentSearchInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    indexId: Scalars['ID']['input'];
    name: Scalars['String']['input'];
    search: InstrumentSearchInput;
};

export type CreateLoginAttemptInput = {
    email: Scalars['String']['input'];
};

export type CreateOrAddUserToOrganizationInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    organizationId: Scalars['ID']['input'];
    role?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateOrUpdateIndexDraftInput = {
    baseVersionId?: InputMaybe<Scalars['Int']['input']>;
    indexBlob: Scalars['String']['input'];
    organizationId?: InputMaybe<Scalars['ID']['input']>;
    token: Scalars['String']['input'];
};

export type CreateOrUpdateIndexDraftResult = FieldErrors | IndexDraft;

export type CreateOrganizationUserInviteInput = {
    email: Scalars['String']['input'];
    message?: InputMaybe<Scalars['String']['input']>;
    organizationId: Scalars['ID']['input'];
    role: OrganizationUserRole;
};

export type CreateOrganizationUserInviteResult = FieldErrors | OrganizationUserInvite;

export type CreateProjectInputType = {
    createdByUserId: Scalars['ID']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    organizationId: Scalars['ID']['input'];
    parentProjectId?: InputMaybe<Scalars['ID']['input']>;
    researchNoteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    title: Scalars['String']['input'];
};

export type CreateProjectResult = FieldErrors | Project;

export type CreateResearchNoteInputType = {
    analystRequestId?: InputMaybe<Scalars['ID']['input']>;
    analystThemeField?: InputMaybe<ResultsModuleType>;
    evaluationField?: InputMaybe<CompanyEvaluationModuleType>;
    evaluationRequestId?: InputMaybe<Scalars['ID']['input']>;
    indexId?: InputMaybe<Scalars['ID']['input']>;
    instrumentField?: InputMaybe<CompanyInfoSectionGroupKeys>;
    instrumentId?: InputMaybe<Scalars['ID']['input']>;
    note?: InputMaybe<Scalars['String']['input']>;
    organizationId: Scalars['ID']['input'];
    title: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
};

export type CreateResearchNoteResult = FieldErrors | ResearchNote;

export type CreateThematicIndexBenchmarkIndexInput = {
    indexId: Scalars['ID']['input'];
};

export type CreateUserAndOrganizationInput = {
    email: Scalars['String']['input'];
    inviteId?: InputMaybe<Scalars['ID']['input']>;
    name: Scalars['String']['input'];
    organizationName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserResult = CreateUserSuccess | FieldErrors;

export type CreateUserSessionInput = {
    challengeToken: Scalars['String']['input'];
    loginAttemptId: Scalars['String']['input'];
};

export type CreateUserSessionResult = Error | UserSession;

export type CreateUserSuccess = {
    __typename?: 'CreateUserSuccess';
    loginAttemptId?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

export type CursorPageType = {
    cursor?: Maybe<Scalars['Cursor']['output']>;
    limit?: Maybe<Scalars['Int']['output']>;
    nextCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type CursorPaginationInput = {
    cursor?: InputMaybe<Scalars['Cursor']['input']>;
    limit: Scalars['Int']['input'];
};

export type CustomWeightedStrategy = BaseStrategy &
    StaticComponentStrategy & {
        __typename?: 'CustomWeightedStrategy';
        /** List of components */
        components: Array<Instrument>;
        emptyField: Scalars['String']['output'];
        numComponents: Scalars['Int']['output'];
        /** List of custom weighted components */
        weightedComponents: Array<WeightedIndexComponent>;
    };

export type DataFrame = {
    __typename?: 'DataFrame';
    columns: Array<Scalars['String']['output']>;
    index: Array<Scalars['String']['output']>;
    rows: Array<Scalars['JSON']['output']>;
};

export type DatedValue = {
    __typename?: 'DatedValue';
    asOfDate: Scalars['Date']['output'];
    value: Scalars['Decimal']['output'];
};

export type DeleteUserAvatarImageResult = Error | User;

export type EarningsCallEvaluation = {
    __typename?: 'EarningsCallEvaluation';
    analystsFocus?: Maybe<Scalars['String']['output']>;
    date: Scalars['Date']['output'];
    id: Scalars['String']['output'];
    insights: Scalars['String']['output'];
    managementFocus?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    previousQuarter?: Maybe<EarningsCallEvaluation>;
    summary: Scalars['String']['output'];
};

export type EqualWeightedStrategy = BaseStrategy &
    StaticComponentStrategy & {
        __typename?: 'EqualWeightedStrategy';
        /** List of components */
        components: Array<Instrument>;
        emptyField: Scalars['String']['output'];
        numComponents: Scalars['Int']['output'];
    };

export type Error = {
    __typename?: 'Error';
    message: Scalars['String']['output'];
};

export type FgpCaEvent = {
    __typename?: 'FgpCaEvent';
    amtDefTradingUnadj?: Maybe<Scalars['Decimal']['output']>;
    amtGrossTradingUnadj?: Maybe<Scalars['Decimal']['output']>;
    amtNetTradingUnadj?: Maybe<Scalars['Decimal']['output']>;
    caEventId: Scalars['String']['output'];
    caEventTypeCode: Scalars['String']['output'];
    distInstFsymId?: Maybe<Scalars['String']['output']>;
    distNewTerm?: Maybe<Scalars['Decimal']['output']>;
    distOldTerm?: Maybe<Scalars['Decimal']['output']>;
    distPct?: Maybe<Scalars['Decimal']['output']>;
    dividendActiveFlag?: Maybe<Scalars['Boolean']['output']>;
    effectiveDate?: Maybe<Scalars['Date']['output']>;
    fsymId: Scalars['ID']['output'];
    payDate?: Maybe<Scalars['Date']['output']>;
    rightsIssueCurrency?: Maybe<Scalars['String']['output']>;
    rightsIssuePrice?: Maybe<Scalars['Decimal']['output']>;
    shortDesc?: Maybe<Scalars['String']['output']>;
    tradingCurrency?: Maybe<Scalars['String']['output']>;
};

export type FgpGlobalPrice = {
    __typename?: 'FgpGlobalPrice';
    currency?: Maybe<Scalars['String']['output']>;
    fsymId: Scalars['ID']['output'];
    priceClose?: Maybe<Scalars['Decimal']['output']>;
    priceDate: Scalars['Date']['output'];
    priceOpen?: Maybe<Scalars['Decimal']['output']>;
    volume?: Maybe<Scalars['Decimal']['output']>;
};

export enum Field {
    AverageDailyVolumeLastMonth = 'AVERAGE_DAILY_VOLUME_LAST_MONTH',
    AverageDailyVolumeTrailingThreeMonthsUsd = 'AVERAGE_DAILY_VOLUME_TRAILING_THREE_MONTHS_USD',
    Beta = 'BETA',
    BusinessDescription = 'BUSINESS_DESCRIPTION',
    CashConversionRatio = 'CASH_CONVERSION_RATIO',
    Ceo = 'CEO',
    City = 'CITY',
    CompanyName = 'COMPANY_NAME',
    Currency = 'CURRENCY',
    DebtToEquityRatio = 'DEBT_TO_EQUITY_RATIO',
    DividendPerShare = 'DIVIDEND_PER_SHARE',
    DividendYieldDaily = 'DIVIDEND_YIELD_DAILY',
    DividendYieldDailyPercent = 'DIVIDEND_YIELD_DAILY_PERCENT',
    EarningsPerShare = 'EARNINGS_PER_SHARE',
    Ebitda = 'EBITDA',
    EbitdaMargin = 'EBITDA_MARGIN',
    EnterpriseValueRevenueRatio = 'ENTERPRISE_VALUE_REVENUE_RATIO',
    Exchange = 'EXCHANGE',
    FloatAsPercentageOfSharesOutstandingUsd = 'FLOAT_AS_PERCENTAGE_OF_SHARES_OUTSTANDING_USD',
    FreeFloatMarketCapitalizationUsd = 'FREE_FLOAT_MARKET_CAPITALIZATION_USD',
    GrossIncomeMargin = 'GROSS_INCOME_MARGIN',
    Id = 'ID',
    IndustryName = 'INDUSTRY_NAME',
    InstrumentType = 'INSTRUMENT_TYPE',
    IsoCountryCode = 'ISO_COUNTRY_CODE',
    LongBusinessDescription = 'LONG_BUSINESS_DESCRIPTION',
    MarketCapitalizationUsd = 'MARKET_CAPITALIZATION_USD',
    NetIncome = 'NET_INCOME',
    NetIncomeMargin = 'NET_INCOME_MARGIN',
    NetRevenueRetention = 'NET_REVENUE_RETENTION',
    OneYearAnnualRevenueGrowthRate = 'ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE',
    OneYearPricePerformance = 'ONE_YEAR_PRICE_PERFORMANCE',
    PriceEarningsRatio = 'PRICE_EARNINGS_RATIO',
    PriceThreeMonthDrawdown = 'PRICE_THREE_MONTH_DRAWDOWN',
    PriceThreeMonthRollingVolatility = 'PRICE_THREE_MONTH_ROLLING_VOLATILITY',
    PriceThreeMonthSharpeRatio = 'PRICE_THREE_MONTH_SHARPE_RATIO',
    PriceThreeMonthStandardDeviation = 'PRICE_THREE_MONTH_STANDARD_DEVIATION',
    PriceToBookRatio = 'PRICE_TO_BOOK_RATIO',
    PriceToSalesRatio = 'PRICE_TO_SALES_RATIO',
    PrimaryRegion = 'PRIMARY_REGION',
    PrimaryRegionCountryCode = 'PRIMARY_REGION_COUNTRY_CODE',
    ReturnOnAssets = 'RETURN_ON_ASSETS',
    ReturnOnInvestedCapital = 'RETURN_ON_INVESTED_CAPITAL',
    ReturnOnTotalCapital = 'RETURN_ON_TOTAL_CAPITAL',
    ReturnOnTotalEquity = 'RETURN_ON_TOTAL_EQUITY',
    SalesOrRevenue = 'SALES_OR_REVENUE',
    SectorName = 'SECTOR_NAME',
    State = 'STATE',
    Symbol = 'SYMBOL',
    ThreeYearPricePerformance = 'THREE_YEAR_PRICE_PERFORMANCE',
    TotalAssets = 'TOTAL_ASSETS',
    TotalEnterpriseValue = 'TOTAL_ENTERPRISE_VALUE',
    TotalLiabilities = 'TOTAL_LIABILITIES',
    Website = 'WEBSITE',
}

export type FieldError = {
    __typename?: 'FieldError';
    field: Scalars['String']['output'];
    message: Scalars['String']['output'];
};

export type FieldErrors = {
    __typename?: 'FieldErrors';
    errors: Array<FieldError>;
};

export type FieldSort = {
    __typename?: 'FieldSort';
    descending: Scalars['Boolean']['output'];
    field: Scalars['String']['output'];
};

export type FollowIndexInput = {
    indexId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type FollowIndexResult = FieldErrors | FollowIndexSuccess;

export type FollowIndexSuccess = {
    __typename?: 'FollowIndexSuccess';
    success: Scalars['Boolean']['output'];
};

export enum FundamentalName {
    AverageDailyVolumeLastMonth = 'average_daily_volume_last_month',
    AverageDailyVolumeTrailingThreeMonthsUsd = 'average_daily_volume_trailing_three_months_usd',
    Beta = 'beta',
    BusinessDescription = 'business_description',
    CashConversionRatio = 'cash_conversion_ratio',
    Ceo = 'ceo',
    City = 'city',
    CompanyName = 'company_name',
    DebtToEquityRatio = 'debt_to_equity_ratio',
    DividendPerShare = 'dividend_per_share',
    DividendYieldDaily = 'dividend_yield_daily',
    DividendYieldDailyPercent = 'dividend_yield_daily_percent',
    EarningsPerShare = 'earnings_per_share',
    Ebitda = 'ebitda',
    EbitdaMargin = 'ebitda_margin',
    EnterpriseValueRevenueRatio = 'enterprise_value_revenue_ratio',
    FiveYearCompoundAnnualRevenueGrowthRate = 'five_year_compound_annual_revenue_growth_rate',
    FloatAsPercentageOfSharesOutstandingUsd = 'float_as_percentage_of_shares_outstanding_usd',
    FreeFloatMarketCapitalizationUsd = 'free_float_market_capitalization_usd',
    FundamentalsAsOf = 'fundamentals_as_of',
    FutureAssetsUpcomingQuarter = 'future_assets_upcoming_quarter',
    FutureAssetsUpcomingYear = 'future_assets_upcoming_year',
    FutureCfpsUpcomingQuarter = 'future_cfps_upcoming_quarter',
    FutureCfpsUpcomingYear = 'future_cfps_upcoming_year',
    FutureDpsUpcomingQuarter = 'future_dps_upcoming_quarter',
    FutureDpsUpcomingYear = 'future_dps_upcoming_year',
    FutureEbitdaUpcomingQuarter = 'future_ebitda_upcoming_quarter',
    FutureEbitdaUpcomingYear = 'future_ebitda_upcoming_year',
    FutureEpsUpcomingQuarter = 'future_eps_upcoming_quarter',
    FutureEpsUpcomingYear = 'future_eps_upcoming_year',
    FutureNetIncUpcomingQuarter = 'future_net_inc_upcoming_quarter',
    FutureNetIncUpcomingYear = 'future_net_inc_upcoming_year',
    FutureSalesUpcomingQuarter = 'future_sales_upcoming_quarter',
    FutureSalesUpcomingYear = 'future_sales_upcoming_year',
    GrossIncomeMargin = 'gross_income_margin',
    IndustryName = 'industry_name',
    IsoCountryCode = 'iso_country_code',
    LongBusinessDescription = 'long_business_description',
    MarketCapitalizationUsd = 'market_capitalization_usd',
    NetIncome = 'net_income',
    NetIncomeMargin = 'net_income_margin',
    NetRevenueRetention = 'net_revenue_retention',
    OneYearAnnualRevenueGrowthRate = 'one_year_annual_revenue_growth_rate',
    OneYearPricePerformance = 'one_year_price_performance',
    PriceEarningsRatio = 'price_earnings_ratio',
    PriceThreeMonthDrawdown = 'price_three_month_drawdown',
    PriceThreeMonthRollingVolatility = 'price_three_month_rolling_volatility',
    PriceThreeMonthSharpeRatio = 'price_three_month_sharpe_ratio',
    PriceThreeMonthStandardDeviation = 'price_three_month_standard_deviation',
    PriceToBookRatio = 'price_to_book_ratio',
    PriceToSalesRatio = 'price_to_sales_ratio',
    ReturnOnAssets = 'return_on_assets',
    ReturnOnInvestedCapital = 'return_on_invested_capital',
    ReturnOnTotalCapital = 'return_on_total_capital',
    ReturnOnTotalEquity = 'return_on_total_equity',
    SalesOrRevenue = 'sales_or_revenue',
    SectorName = 'sector_name',
    SharesOutstanding = 'shares_outstanding',
    State = 'state',
    ThreeYearCompoundAnnualRevenueGrowthRate = 'three_year_compound_annual_revenue_growth_rate',
    ThreeYearPricePerformance = 'three_year_price_performance',
    TotalAssets = 'total_assets',
    TotalEnterpriseValue = 'total_enterprise_value',
    TotalLiabilities = 'total_liabilities',
    Website = 'website',
}

export type FundamentalsEvaluation = {
    __typename?: 'FundamentalsEvaluation';
    analysis: Scalars['String']['output'];
    id: Scalars['String']['output'];
    insights: Scalars['String']['output'];
    quarterlyFinancials?: Maybe<QuarterlyFinancials>;
};

export type Index = {
    __typename?: 'Index';
    aboutUrl?: Maybe<Scalars['String']['output']>;
    activeVersion?: Maybe<IndexVersion>;
    annualCostRate?: Maybe<Scalars['Decimal']['output']>;
    areComponentsPublic: Scalars['Boolean']['output'];
    bannerUrl?: Maybe<Scalars['String']['output']>;
    baseDate: Scalars['Date']['output'];
    baseValue: Scalars['Decimal']['output'];
    benchmarkIndexes: Array<BenchmarkIndex>;
    canCurrentUserEdit: Scalars['Boolean']['output'];
    canDuplicate: Scalars['Boolean']['output'];
    canUpdateName: Scalars['Boolean']['output'];
    createdAt: Scalars['UTCDateTime']['output'];
    createdByUser?: Maybe<User>;
    currencyCode: Scalars['String']['output'];
    dailySnapshots: Array<IndexDailySnapshot>;
    dailySnapshotsLessCosts: Array<IndexDailySnapshotLessCosts>;
    description: Scalars['String']['output'];
    displayedFundamentalsColumns: Array<FundamentalName>;
    earliestSnapshot?: Maybe<IndexDailySnapshot>;
    errorAndRecentProcessIndexAttempts: Array<ProcessIndexAttempt>;
    firstEffectiveAt?: Maybe<Scalars['UTCDateTime']['output']>;
    followCount: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    isBloombergEnabled: Scalars['Boolean']['output'];
    isCalculationUpToDate: Scalars['Boolean']['output'];
    isComponentsPrivate: Scalars['Boolean']['output'];
    isFeatured: Scalars['Boolean']['output'];
    isFollowedByCurrentUser: Scalars['Boolean']['output'];
    isHidden: Scalars['Boolean']['output'];
    isInverse: Scalars['Boolean']['output'];
    isLocked: Scalars['Boolean']['output'];
    isNotable: Scalars['Boolean']['output'];
    isPortfolio: Scalars['Boolean']['output'];
    isProcessingDisabled: Scalars['Boolean']['output'];
    isPromotionDisabled: Scalars['Boolean']['output'];
    latestSnapshot?: Maybe<IndexDailySnapshot>;
    longDescription?: Maybe<Scalars['String']['output']>;
    methodologyBlogSlug?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    numTags: Scalars['Int']['output'];
    opengraphUrl?: Maybe<Scalars['String']['output']>;
    organization: Organization;
    organizationId: Scalars['ID']['output'];
    originatingAnalystThemeRequestId?: Maybe<Scalars['ID']['output']>;
    performance: Scalars['Decimal']['output'];
    processIndexAttempts: Array<ProcessIndexAttempt>;
    recentUpdateMessage?: Maybe<Scalars['String']['output']>;
    similarIndexes: Array<Index>;
    symbol?: Maybe<Scalars['String']['output']>;
    tags: Array<Tag>;
    versions: Array<IndexVersion>;
};

export type IndexActiveVersionArgs = {
    asOf?: InputMaybe<Scalars['UTCDateTime']['input']>;
};

export type IndexDailySnapshotsArgs = {
    maxAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    minAsOfDate?: InputMaybe<Scalars['Date']['input']>;
};

export type IndexDailySnapshotsLessCostsArgs = {
    maxAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    minAsOfDate?: InputMaybe<Scalars['Date']['input']>;
};

export type IndexPerformanceArgs = {
    maxAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    minAsOfDate: Scalars['Date']['input'];
};

export type IndexDailySnapshot = {
    __typename?: 'IndexDailySnapshot';
    asOfDate: Scalars['Date']['output'];
    benchmarks: Array<IndexDailySnapshotBenchmark>;
    components: Array<IndexDailySnapshotComponent>;
    componentsIfNotPrivate?: Maybe<Array<IndexDailySnapshotComponent>>;
    currencyCode: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isBackcalculated: Scalars['Boolean']['output'];
    netAssetValue: Scalars['Decimal']['output'];
};

export type IndexDailySnapshotComponentsArgs = {
    includeZeroShares?: Scalars['Boolean']['input'];
};

export type IndexDailySnapshotComponentsIfNotPrivateArgs = {
    includeZeroShares?: Scalars['Boolean']['input'];
};

export type IndexDailySnapshotBenchmark = {
    __typename?: 'IndexDailySnapshotBenchmark';
    level: Scalars['Decimal']['output'];
    symbol: Scalars['String']['output'];
};

export type IndexDailySnapshotComponent = {
    __typename?: 'IndexDailySnapshotComponent';
    id: Scalars['ID']['output'];
    instrument: Instrument;
    instrumentId: Scalars['String']['output'];
    numShares: Scalars['Decimal']['output'];
    value: Scalars['Decimal']['output'];
};

export type IndexDailySnapshotLessCosts = {
    __typename?: 'IndexDailySnapshotLessCosts';
    asOfDate: Scalars['Date']['output'];
    currencyCode: Scalars['String']['output'];
    id: Scalars['String']['output'];
    indexId: Scalars['String']['output'];
    isBackcalculated: Scalars['Boolean']['output'];
    netAssetValue: Scalars['Decimal']['output'];
};

export type IndexDraft = {
    __typename?: 'IndexDraft';
    indexBlob: Scalars['String']['output'];
    organization?: Maybe<Organization>;
    token?: Maybe<Scalars['String']['output']>;
    user: User;
    userSession: UserSession;
    versionId?: Maybe<Scalars['Int']['output']>;
};

export type IndexPage = CursorPageType & {
    __typename?: 'IndexPage';
    cursor?: Maybe<Scalars['Cursor']['output']>;
    indexes: Array<Index>;
    limit?: Maybe<Scalars['Int']['output']>;
    nextCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type IndexVersion = {
    __typename?: 'IndexVersion';
    description?: Maybe<Scalars['String']['output']>;
    effectiveAt?: Maybe<Scalars['UTCDateTime']['output']>;
    hasRebalanceSchedule: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    index: Index;
    indexBenchmarkIndexes: Array<BenchmarkIndex>;
    indexDescription: Scalars['String']['output'];
    indexId: Scalars['ID']['output'];
    indexLongDescription?: Maybe<Scalars['String']['output']>;
    indexName: Scalars['String']['output'];
    name: Scalars['String']['output'];
    rebalanceAfterPublish: Scalars['Boolean']['output'];
    strategy: BaseStrategy;
    strategyIfNotPrivate?: Maybe<BaseStrategy>;
};

export type InferredInstrumentSearchFiltersResult = {
    __typename?: 'InferredInstrumentSearchFiltersResult';
    parsedFilters: Array<InstrumentSearchPredicate>;
    rawAllJson: Scalars['String']['output'];
    rawFailedToParseJson: Scalars['String']['output'];
    themeQuery: Scalars['String']['output'];
};

export type InferredReferenceInstrument = {
    __typename?: 'InferredReferenceInstrument';
    instrumentCompanyName?: Maybe<Scalars['String']['output']>;
    instrumentId?: Maybe<Scalars['String']['output']>;
    userQuerySubstring?: Maybe<Scalars['String']['output']>;
};

export type Instrument = {
    __typename?: 'Instrument';
    adjustedPricePerformance: Scalars['Decimal']['output'];
    adjustedPriceValue?: Maybe<Scalars['Decimal']['output']>;
    adjustedPriceValues: Array<DatedValue>;
    articles: Array<InstrumentArticle>;
    bloombergTickerOrError: Scalars['String']['output'];
    companyName: Scalars['String']['output'];
    createdAt: Scalars['UTCDateTime']['output'];
    currentSymbol?: Maybe<Scalars['String']['output']>;
    exchange?: Maybe<Scalars['String']['output']>;
    exchangeName?: Maybe<Scalars['String']['output']>;
    factsetEntityId?: Maybe<Scalars['String']['output']>;
    factsetListingFsymId?: Maybe<Scalars['String']['output']>;
    factsetPriceFsymId?: Maybe<Scalars['String']['output']>;
    factsetRegionalFsymId?: Maybe<Scalars['String']['output']>;
    factsetSecurityFsymId?: Maybe<Scalars['String']['output']>;
    figi?: Maybe<Scalars['String']['output']>;
    fundamentals: Array<InstrumentFundamental>;
    id: Scalars['ID']['output'];
    identifiers: Array<InstrumentIdentifier>;
    instrumentType?: Maybe<Scalars['String']['output']>;
    isCash: Scalars['Boolean']['output'];
    isin?: Maybe<Scalars['String']['output']>;
    latestFundamentals?: Maybe<InstrumentFundamentalsSet>;
    numArticles: Scalars['Int']['output'];
    primaryRegion?: Maybe<Scalars['String']['output']>;
    primaryRegionCountryCode?: Maybe<Scalars['String']['output']>;
    similarInstruments: Array<Instrument>;
    snapshotComponentIndexes: Array<Index>;
    strategyIndexVersions: Array<IndexVersion>;
    strategyIndexes: Array<Index>;
    symbol?: Maybe<Scalars['String']['output']>;
};

export type InstrumentAdjustedPricePerformanceArgs = {
    maxAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    minAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    pastThreeMonthsOnly?: Scalars['Boolean']['input'];
};

export type InstrumentAdjustedPriceValueArgs = {
    asOf: Scalars['Date']['input'];
};

export type InstrumentAdjustedPriceValuesArgs = {
    maxAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    minAsOfDate?: InputMaybe<Scalars['Date']['input']>;
    pastThreeMonthsOnly?: Scalars['Boolean']['input'];
};

export type InstrumentFundamentalsArgs = {
    fieldNames?: InputMaybe<Array<Scalars['String']['input']>>;
    forSymbol?: InputMaybe<Scalars['String']['input']>;
};

export type InstrumentAdjustedPriceType = {
    __typename?: 'InstrumentAdjustedPriceType';
    asOfDate: Scalars['Date']['output'];
    atClose: Scalars['Decimal']['output'];
    createdAt: Scalars['UTCDateTime']['output'];
    currencyCode: Scalars['String']['output'];
    instrument: Instrument;
};

export type InstrumentAnalysis = {
    __typename?: 'InstrumentAnalysis';
    analysis?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['UTCDateTime']['output'];
    excerpts?: Maybe<Array<InstrumentArticleAnalysisExcerpt>>;
    finishedAt?: Maybe<Scalars['UTCDateTime']['output']>;
    id: Scalars['ID']['output'];
    instrument: Instrument;
    isCompleted: Scalars['Boolean']['output'];
    query: Scalars['String']['output'];
    startedAt: Scalars['UTCDateTime']['output'];
    state?: Maybe<Scalars['String']['output']>;
    timer?: Maybe<Scalars['Float']['output']>;
    user?: Maybe<User>;
    userQuery: Scalars['String']['output'];
};

export type InstrumentArticle = {
    __typename?: 'InstrumentArticle';
    body: Scalars['String']['output'];
    citationLink: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    preview: Scalars['String']['output'];
    readableSource: Scalars['String']['output'];
    source: Scalars['String']['output'];
};

export type InstrumentArticleAnalysisExcerpt = {
    __typename?: 'InstrumentArticleAnalysisExcerpt';
    article: InstrumentArticle;
    instrumentArticleId: Scalars['ID']['output'];
    sentences: Array<InstrumentArticleAnalysisExcerptSentence>;
};

export type InstrumentArticleAnalysisExcerptSentence = {
    __typename?: 'InstrumentArticleAnalysisExcerptSentence';
    htmlText: Scalars['String']['output'];
    isHighlighted: Scalars['Boolean']['output'];
    normalizedMatchScore: Scalars['Float']['output'];
    sentenceEmbeddingId: Scalars['Int']['output'];
};

export type InstrumentArticleCount = {
    __typename?: 'InstrumentArticleCount';
    instrumentArticleCount: Scalars['Int']['output'];
    instrumentArticleType: Scalars['String']['output'];
};

export type InstrumentCashCurrencyCode = InstrumentIdentifier & {
    __typename?: 'InstrumentCashCurrencyCode';
    activeDuring?: Maybe<UtcDateTimeRange>;
    cashCurrencyCode: Scalars['String']['output'];
    id: Scalars['String']['output'];
};

export type InstrumentCoinGecko = InstrumentIdentifier & {
    __typename?: 'InstrumentCoinGecko';
    activeDuring?: Maybe<UtcDateTimeRange>;
    coingeckoId: Scalars['String']['output'];
    id: Scalars['String']['output'];
};

export type InstrumentData = {
    __typename?: 'InstrumentData';
    distributions: Array<InstrumentDataDistribution>;
    spinoffs: Array<InstrumentDataSpinoff>;
};

export type InstrumentDataDistribution = {
    __typename?: 'InstrumentDataDistribution';
    eventIdentifier?: Maybe<Scalars['String']['output']>;
    exDate: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    instrument: Instrument;
    payDate?: Maybe<Scalars['Date']['output']>;
    source: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type InstrumentDataManualActiveRule = {
    __typename?: 'InstrumentDataManualActiveRule';
    createdAt: Scalars['UTCDateTime']['output'];
    createdByUser: User;
    endDate?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    instrument: Instrument;
    isActive: Scalars['Boolean']['output'];
    startDate?: Maybe<Scalars['Date']['output']>;
};

export type InstrumentDataSpinoff = {
    __typename?: 'InstrumentDataSpinoff';
    distributedInstrument?: Maybe<Instrument>;
    eventIdentifier?: Maybe<Scalars['String']['output']>;
    exDate: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    instrument: Instrument;
    payDate?: Maybe<Scalars['Date']['output']>;
    source: Scalars['String']['output'];
};

export type InstrumentEvaluation = {
    __typename?: 'InstrumentEvaluation';
    aiChatRequests: Array<AiChatRequest>;
    analysis?: Maybe<Scalars['String']['output']>;
    benchmarkPriceHistory?: Maybe<PriceHistoryEvaluation>;
    benchmarks: Array<Instrument>;
    date: Scalars['Date']['output'];
    earningsCallEvaluation?: Maybe<EarningsCallEvaluation>;
    fundamentalsAndRatios?: Maybe<DataFrame>;
    fundamentalsEvaluation?: Maybe<FundamentalsEvaluation>;
    id: Scalars['String']['output'];
    insights?: Maybe<Scalars['String']['output']>;
    instrument: Instrument;
    isCompleted: Scalars['Boolean']['output'];
    newsEvaluation?: Maybe<InstrumentFullNewsEvaluation>;
    peerFundamentals?: Maybe<PeerFundamentalsEvaluation>;
    peerPriceHistory?: Maybe<PriceHistoryEvaluation>;
    peers: Array<Instrument>;
    priceHistoryEnd: Scalars['Date']['output'];
    priceHistoryStart: Scalars['Date']['output'];
    secFilingEvaluation?: Maybe<SecFilingEvaluation>;
    valuation?: Maybe<Scalars['String']['output']>;
    valuationReasoning?: Maybe<Scalars['String']['output']>;
};

export type InstrumentEvaluationComp = {
    __typename?: 'InstrumentEvaluationComp';
    baseInstrument: Instrument;
    compInstruments: Array<Scalars['String']['output']>;
    forOrganizationId?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
};

export type InstrumentEvaluationRequest = {
    __typename?: 'InstrumentEvaluationRequest';
    evaluation: InstrumentEvaluation;
    id: Scalars['ID']['output'];
    instrument: Instrument;
    notificationSentAt?: Maybe<Scalars['UTCDateTime']['output']>;
    requestedAt: Scalars['UTCDateTime']['output'];
    seenFinishedAt?: Maybe<Scalars['UTCDateTime']['output']>;
    user?: Maybe<User>;
};

export type InstrumentExchangeSymbol = InstrumentIdentifier & {
    __typename?: 'InstrumentExchangeSymbol';
    activeDuring?: Maybe<UtcDateTimeRange>;
    exchange: Scalars['String']['output'];
    id: Scalars['String']['output'];
    symbol: Scalars['String']['output'];
};

export type InstrumentFigi = InstrumentIdentifier & {
    __typename?: 'InstrumentFigi';
    activeDuring?: Maybe<UtcDateTimeRange>;
    figi: Scalars['String']['output'];
    id: Scalars['String']['output'];
};

export type InstrumentFilter = {
    __typename?: 'InstrumentFilter';
    id: Scalars['ID']['output'];
    isCompleted: Scalars['Boolean']['output'];
    predicate?: Maybe<Scalars['JSON']['output']>;
    userId: Scalars['ID']['output'];
    userQuery: Scalars['String']['output'];
};

export type InstrumentFullNewsEvaluation = {
    __typename?: 'InstrumentFullNewsEvaluation';
    analysis?: Maybe<Array<NewsItem>>;
    createdAt: Scalars['UTCDateTime']['output'];
    date: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    instrument: Instrument;
    keyTakeaways?: Maybe<Array<NewsItem>>;
    news?: Maybe<Array<NewsItem>>;
    riskFactors?: Maybe<Array<NewsItem>>;
    sentiment?: Maybe<Scalars['String']['output']>;
    sentimentRating?: Maybe<Scalars['String']['output']>;
    trends?: Maybe<Array<NewsItem>>;
};

export type InstrumentFundamental = {
    name: FundamentalName;
};

export type InstrumentFundamentalDate = InstrumentFundamental & {
    __typename?: 'InstrumentFundamentalDate';
    dateValue?: Maybe<Scalars['Date']['output']>;
    name: FundamentalName;
};

export type InstrumentFundamentalDecimal = InstrumentFundamental & {
    __typename?: 'InstrumentFundamentalDecimal';
    decimalValue?: Maybe<Scalars['Decimal']['output']>;
    name: FundamentalName;
};

export type InstrumentFundamentalString = InstrumentFundamental & {
    __typename?: 'InstrumentFundamentalString';
    name: FundamentalName;
    stringValue?: Maybe<Scalars['String']['output']>;
};

export type InstrumentFundamentalsSet = {
    __typename?: 'InstrumentFundamentalsSet';
    averageDailyVolumeTrailingThreeMonthsUsd?: Maybe<Scalars['Decimal']['output']>;
    businessDescription?: Maybe<Scalars['String']['output']>;
    cashConversionRatio?: Maybe<Scalars['Decimal']['output']>;
    ceo?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    companyName?: Maybe<Scalars['String']['output']>;
    dividendYieldDailyPercent?: Maybe<Scalars['Decimal']['output']>;
    dividendsPerShare?: Maybe<Scalars['Decimal']['output']>;
    earningsPerShare?: Maybe<Scalars['Decimal']['output']>;
    ebitdaMargin?: Maybe<Scalars['Decimal']['output']>;
    enterpriseValueRevenueRatio?: Maybe<Scalars['Decimal']['output']>;
    floatAsPercentageOfSharesOutstandingUsd?: Maybe<Scalars['Decimal']['output']>;
    fundamentalsAsOf?: Maybe<Scalars['Date']['output']>;
    grossIncomeMargin?: Maybe<Scalars['Decimal']['output']>;
    industryName?: Maybe<Scalars['String']['output']>;
    isoCountryCode?: Maybe<Scalars['String']['output']>;
    marketCapitalizationUsd?: Maybe<Scalars['Decimal']['output']>;
    netRevenueRetention?: Maybe<Scalars['Decimal']['output']>;
    oneYearAnnualRevenueGrowthRate?: Maybe<Scalars['Decimal']['output']>;
    priceEarningsRatio?: Maybe<Scalars['Decimal']['output']>;
    returnOnTotalCapital?: Maybe<Scalars['Decimal']['output']>;
    salesOrRevenue?: Maybe<Scalars['Decimal']['output']>;
    sectorName?: Maybe<Scalars['String']['output']>;
    sharesOutstanding?: Maybe<Scalars['Decimal']['output']>;
    state?: Maybe<Scalars['String']['output']>;
    totalAssets?: Maybe<Scalars['Decimal']['output']>;
    totalEnterpriseValue?: Maybe<Scalars['Decimal']['output']>;
    totalLiabilities?: Maybe<Scalars['Decimal']['output']>;
    website?: Maybe<Scalars['String']['output']>;
};

export type InstrumentIdentifier = {
    activeDuring?: Maybe<UtcDateTimeRange>;
    id: Scalars['String']['output'];
};

export type InstrumentIndustry = {
    __typename?: 'InstrumentIndustry';
    name: Scalars['String']['output'];
};

export type InstrumentIsin = InstrumentIdentifier & {
    __typename?: 'InstrumentIsin';
    activeDuring?: Maybe<UtcDateTimeRange>;
    id: Scalars['String']['output'];
    isin: Scalars['String']['output'];
};

export type InstrumentNews = {
    __typename?: 'InstrumentNews';
    fullEvaluation?: Maybe<InstrumentFullNewsEvaluation>;
    instrument: Instrument;
};

export type InstrumentNewsArticle = {
    __typename?: 'InstrumentNewsArticle';
    image?: Maybe<Scalars['String']['output']>;
    instrument: Instrument;
    publishedAt: Scalars['UTCDateTime']['output'];
    site: Scalars['String']['output'];
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type InstrumentNewsStats = {
    __typename?: 'InstrumentNewsStats';
    instrument: Instrument;
    lastDownloaded: Scalars['Date']['output'];
    lastFullEvaluationDate?: Maybe<Scalars['Date']['output']>;
    lastSixtyDaysCount: Scalars['Int']['output'];
    sentimentRating?: Maybe<Scalars['String']['output']>;
};

export type InstrumentResult = PerformanceComparisonResult & {
    __typename?: 'InstrumentResult';
    analysis?: Maybe<AnalysisFigures>;
    instrument: Instrument;
    points: Array<DatedValue>;
};

export type InstrumentSearch = {
    __typename?: 'InstrumentSearch';
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    indexId: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    /** Predicate */
    predicate: InstrumentSearchPredicate;
};

export type InstrumentSearchAndFilterInput = {
    filters: Array<InstrumentSearchNonGlobalFilterInput>;
};

export type InstrumentSearchAndPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchAndPredicate';
    asString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    /** Predicates combined as `and` clauses */
    predicates: Array<InstrumentSearchPredicate>;
    type: PredicateType;
};

export type InstrumentSearchBySymbol = {
    exchange?: InputMaybe<Scalars['String']['input']>;
    symbol: Scalars['String']['input'];
};

export type InstrumentSearchCombinedQueryFilterInput = {
    elasticsearchType?: InputMaybe<MultiMatchType>;
    fields: Array<Field>;
    query: Scalars['String']['input'];
};

export type InstrumentSearchCombinedQueryPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchCombinedQueryPredicate';
    asString: Scalars['String']['output'];
    /** Fields */
    fields: Array<Field>;
    /** Fields */
    fieldsAsStrings: Array<Scalars['String']['output']>;
    orderSpec?: Maybe<PredicateOrderSpec>;
    /** Query */
    query: Scalars['String']['output'];
    type: PredicateType;
};

export type InstrumentSearchComparisonFilterInput = {
    comparator: Comparator;
    field: Field;
    value: Scalars['Decimal']['input'];
};

export type InstrumentSearchComparisonPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchComparisonPredicate';
    asString: Scalars['String']['output'];
    /** Comparator */
    comparator: Comparator;
    /** Field */
    field: Field;
    /** Field */
    fieldAsString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    type: PredicateType;
    /** Value */
    value: Scalars['Decimal']['output'];
};

export type InstrumentSearchFilterInput = {
    and?: InputMaybe<InstrumentSearchAndFilterInput>;
    combinedQuery?: InputMaybe<InstrumentSearchCombinedQueryFilterInput>;
    comparison?: InputMaybe<InstrumentSearchComparisonFilterInput>;
    globalContext?: InputMaybe<InstrumentSearchGlobalContextFilterInput>;
    in?: InputMaybe<InstrumentSearchInFilterInput>;
    match?: InputMaybe<InstrumentSearchMatchFilterInput>;
    or?: InputMaybe<InstrumentSearchOrFilterInput>;
    phraseSimilarity?: InputMaybe<InstrumentSearchPhraseSimilarityFilterInput>;
    statisticalOperation?: InputMaybe<InstrumentSearchStatisticalFilterInput>;
};

export type InstrumentSearchGlobalContextFilterInput = {
    context: InstrumentSearchNonGlobalFilterInput;
    filter: InstrumentSearchNonGlobalFilterInput;
};

export type InstrumentSearchGlobalContextPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchGlobalContextPredicate';
    asString: Scalars['String']['output'];
    /** Globally applied context predicate */
    context: InstrumentSearchPredicate;
    orderSpec?: Maybe<PredicateOrderSpec>;
    /** Predicate to evaluate with global context */
    predicate: InstrumentSearchPredicate;
    type: PredicateType;
};

export type InstrumentSearchInFilterInput = {
    field: Field;
    values: Array<Scalars['String']['input']>;
};

export type InstrumentSearchInPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchInPredicate';
    asString: Scalars['String']['output'];
    /** Field */
    field: Field;
    /** Field */
    fieldAsString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    type: PredicateType;
    /** Values */
    values: Array<Scalars['String']['output']>;
};

export type InstrumentSearchInput = {
    filter?: InputMaybe<InstrumentSearchFilterInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type InstrumentSearchMatchFilterInput = {
    field: Field;
    match: Scalars['String']['input'];
};

export type InstrumentSearchMatchPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchMatchPredicate';
    asString: Scalars['String']['output'];
    /** Field */
    field: Field;
    /** Field */
    fieldAsString: Scalars['String']['output'];
    /** Match */
    match: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    type: PredicateType;
};

export type InstrumentSearchNonGlobalFilterInput = {
    and?: InputMaybe<InstrumentSearchAndFilterInput>;
    combinedQuery?: InputMaybe<InstrumentSearchCombinedQueryFilterInput>;
    comparison?: InputMaybe<InstrumentSearchComparisonFilterInput>;
    in?: InputMaybe<InstrumentSearchInFilterInput>;
    match?: InputMaybe<InstrumentSearchMatchFilterInput>;
    or?: InputMaybe<InstrumentSearchOrFilterInput>;
    phraseSimilarity?: InputMaybe<InstrumentSearchPhraseSimilarityFilterInput>;
    statisticalOperation?: InputMaybe<InstrumentSearchStatisticalFilterInput>;
};

export type InstrumentSearchOrFilterInput = {
    filters: Array<InstrumentSearchNonGlobalFilterInput>;
};

export type InstrumentSearchOrPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchOrPredicate';
    asString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    /** Predicates combined as `or` clauses */
    predicates: Array<InstrumentSearchPredicate>;
    type: PredicateType;
};

export type InstrumentSearchPhraseSimilarityFilterInput = {
    query: Scalars['String']['input'];
};

export type InstrumentSearchPhraseSimilarityPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchPhraseSimilarityPredicate';
    asString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    /** Query */
    query: Scalars['String']['output'];
    type: PredicateType;
};

export type InstrumentSearchPredicate =
    | InstrumentSearchAndPredicate
    | InstrumentSearchCombinedQueryPredicate
    | InstrumentSearchComparisonPredicate
    | InstrumentSearchGlobalContextPredicate
    | InstrumentSearchInPredicate
    | InstrumentSearchMatchPredicate
    | InstrumentSearchOrPredicate
    | InstrumentSearchPhraseSimilarityPredicate
    | InstrumentSearchSortLimitPredicate
    | InstrumentSearchStatisticalPredicate;

export type InstrumentSearchPredicateInterface = {
    asString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    type: PredicateType;
};

export type InstrumentSearchResults = {
    __typename?: 'InstrumentSearchResults';
    /** Instruments */
    instruments: Array<Instrument>;
};

export type InstrumentSearchSortLimitPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchSortLimitPredicate';
    asString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    type: PredicateType;
};

export type InstrumentSearchStatisticalContextFilterInput = {
    and?: InputMaybe<InstrumentSearchAndFilterInput>;
    combinedQuery?: InputMaybe<InstrumentSearchCombinedQueryFilterInput>;
    comparison?: InputMaybe<InstrumentSearchComparisonFilterInput>;
    in?: InputMaybe<InstrumentSearchInFilterInput>;
    match?: InputMaybe<InstrumentSearchMatchFilterInput>;
    or?: InputMaybe<InstrumentSearchOrFilterInput>;
    phraseSimilarity?: InputMaybe<InstrumentSearchPhraseSimilarityFilterInput>;
};

export type InstrumentSearchStatisticalFilterInput = {
    comparator: Comparator;
    context?: InputMaybe<InstrumentSearchStatisticalContextFilterInput>;
    field: Field;
    statistic: StatisticalValue;
};

export type InstrumentSearchStatisticalPredicate = InstrumentSearchPredicateInterface & {
    __typename?: 'InstrumentSearchStatisticalPredicate';
    asString: Scalars['String']['output'];
    /** Comparator */
    comparator: Comparator;
    /** Statistical calculation context predicate */
    context?: Maybe<InstrumentSearchPredicate>;
    /** Field */
    field: Field;
    /** Field */
    fieldAsString: Scalars['String']['output'];
    orderSpec?: Maybe<PredicateOrderSpec>;
    /** Statistic */
    statistic: StatisticalValue;
    type: PredicateType;
};

export type InstrumentSector = {
    __typename?: 'InstrumentSector';
    industries: Array<InstrumentIndustry>;
    name: Scalars['String']['output'];
};

export type InstrumentsByVolatilityScoreInput = {
    descending?: Scalars['Boolean']['input'];
    limit: Scalars['Int']['input'];
    maxDate?: InputMaybe<Scalars['Date']['input']>;
    minDate?: InputMaybe<Scalars['Date']['input']>;
    minMarketCapitalizationUsd?: InputMaybe<Scalars['Decimal']['input']>;
};

export type InstrumentsVolatilityResult = {
    __typename?: 'InstrumentsVolatilityResult';
    maxDate: Scalars['Date']['output'];
    minDate: Scalars['Date']['output'];
    sortedEntries: Array<InstrumentsVolatilityResultEntry>;
};

export type InstrumentsVolatilityResultEntry = {
    __typename?: 'InstrumentsVolatilityResultEntry';
    beta: Scalars['Decimal']['output'];
    instrument: Instrument;
    score: Scalars['Decimal']['output'];
};

export enum LimitType {
    Absolute = 'ABSOLUTE',
    Percent = 'PERCENT',
}

export type LogOutUserSessionResult = {
    __typename?: 'LogOutUserSessionResult';
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type MarketCapWeightedStrategy = BaseStrategy &
    StaticComponentStrategy & {
        __typename?: 'MarketCapWeightedStrategy';
        /** List of components */
        components: Array<Instrument>;
        emptyField: Scalars['String']['output'];
        numComponents: Scalars['Int']['output'];
    };

export enum MultiMatchType {
    BestFields = 'BEST_FIELDS',
    PhrasePrefix = 'PHRASE_PREFIX',
}

export type Mutation = {
    __typename?: 'Mutation';
    analystOpenQuery: AnalystOpenQueryResult;
    archiveProject: Scalars['Boolean']['output'];
    createActivityEvent: ActivityEvent;
    createIndex: CreateIndexResult;
    createIndexVersion: CreateIndexVersionResult;
    createInstrumentSearch: InstrumentSearch;
    createLoginAttempt: SendLoginAttemptResult;
    createOrUpdateIndexDraft: CreateOrUpdateIndexDraftResult;
    createOrganizationUserInvite: CreateOrganizationUserInviteResult;
    createPerformanceComparison: PerformanceComparison;
    createProject: CreateProjectResult;
    createResearchNote: CreateResearchNoteResult;
    createUserAndOrganization: CreateUserResult;
    createUserSession: CreateUserSessionResult;
    deleteUserAvatarImage: DeleteUserAvatarImageResult;
    followIndex: FollowIndexResult;
    inferInstrumentFilter: InstrumentFilter;
    instrumentArticle: InstrumentArticle;
    logOutUserSession: LogOutUserSessionResult;
    removeOrganizationUser: RemoveOrganizationUserResult;
    removeProject: Scalars['Boolean']['output'];
    removeResearchNote: Scalars['Boolean']['output'];
    resendLoginAttempt: SendLoginAttemptResult;
    resendOrganizationUserInvite: ResendOrganizationUserInviteResult;
    setInstrumentEvaluationComps: SetInstrumentCompsResult;
    unfollowIndex: FollowIndexResult;
    updateIndexBannerImage: UpdateIndexBannerImageResult;
    updateIndexIsComponentsPrivate: Index;
    updateIndexIsHidden: Index;
    updateOrganizationAvatarImage: UpdateOrganizationAvatarImageResult;
    updateOrganizationProfile: UpdateOrganizationProfileResult;
    updateOrganizationUserRole: OrganizationUser;
    updateProject: UpdateProjectResult;
    updateResearchNote: UpdateResearchNoteResult;
    updateUserAvatarImage: UpdateUserAvatarImageResult;
    updateUserProfile: UpdateUserProfileResult;
    uploadIndexOpengraphImage: UploadIndexOpengraphImageResult;
};

export type MutationAnalystOpenQueryArgs = {
    query: Scalars['String']['input'];
};

export type MutationArchiveProjectArgs = {
    input: ArchiveProjectInput;
};

export type MutationCreateActivityEventArgs = {
    eventData?: InputMaybe<Scalars['JSON']['input']>;
    eventType: Scalars['String']['input'];
    requestingPath?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateIndexArgs = {
    input: CreateIndexInputType;
};

export type MutationCreateIndexVersionArgs = {
    input: CreateIndexVersionInput;
};

export type MutationCreateInstrumentSearchArgs = {
    input: CreateInstrumentSearchInput;
};

export type MutationCreateLoginAttemptArgs = {
    input: CreateLoginAttemptInput;
};

export type MutationCreateOrUpdateIndexDraftArgs = {
    input: CreateOrUpdateIndexDraftInput;
};

export type MutationCreateOrganizationUserInviteArgs = {
    input: CreateOrganizationUserInviteInput;
};

export type MutationCreatePerformanceComparisonArgs = {
    input: PerformanceComparisonInput;
};

export type MutationCreateProjectArgs = {
    input: CreateProjectInputType;
};

export type MutationCreateResearchNoteArgs = {
    input: CreateResearchNoteInputType;
};

export type MutationCreateUserAndOrganizationArgs = {
    input: CreateUserAndOrganizationInput;
};

export type MutationCreateUserSessionArgs = {
    input: CreateUserSessionInput;
};

export type MutationDeleteUserAvatarImageArgs = {
    userId: Scalars['ID']['input'];
};

export type MutationFollowIndexArgs = {
    input: FollowIndexInput;
};

export type MutationInferInstrumentFilterArgs = {
    query: Scalars['String']['input'];
};

export type MutationInstrumentArticleArgs = {
    id: Scalars['ID']['input'];
};

export type MutationRemoveOrganizationUserArgs = {
    input: RemoveOrganizationUserInput;
};

export type MutationRemoveProjectArgs = {
    input: RemoveProjectInput;
};

export type MutationRemoveResearchNoteArgs = {
    input: RemoveResearchNoteInput;
};

export type MutationResendLoginAttemptArgs = {
    input: ResendLoginAttemptInput;
};

export type MutationResendOrganizationUserInviteArgs = {
    input: ResendOrganizationUserInviteInput;
};

export type MutationSetInstrumentEvaluationCompsArgs = {
    request: SetInstrumentCompsRequest;
};

export type MutationUnfollowIndexArgs = {
    input: FollowIndexInput;
};

export type MutationUpdateIndexBannerImageArgs = {
    input: UpdateIndexBannerImageInput;
};

export type MutationUpdateIndexIsComponentsPrivateArgs = {
    input: UpdateIndexIsComponentsPrivateType;
};

export type MutationUpdateIndexIsHiddenArgs = {
    input: UpdateIndexIsHiddenType;
};

export type MutationUpdateOrganizationAvatarImageArgs = {
    input: UpdateOrganizationAvatarImageInput;
};

export type MutationUpdateOrganizationProfileArgs = {
    input: UpdateOrganizationProfileInput;
};

export type MutationUpdateOrganizationUserRoleArgs = {
    input: UpdateOrganizationUserRoleInput;
};

export type MutationUpdateProjectArgs = {
    input: UpdateProjectInput;
};

export type MutationUpdateResearchNoteArgs = {
    input: UpdateResearchNoteInput;
};

export type MutationUpdateUserAvatarImageArgs = {
    input: UpdateUserAvatarImageInput;
};

export type MutationUpdateUserProfileArgs = {
    input: UpdateUserProfileInput;
};

export type MutationUploadIndexOpengraphImageArgs = {
    input: UploadIndexOpengraphImageInput;
};

export type NewsItem = {
    __typename?: 'NewsItem';
    articles: Array<InstrumentNewsArticle>;
    text: Scalars['String']['output'];
};

export type ObfuscatedThemeInstrumentMatchResult = ThemeInstrumentMatchResultBase & {
    __typename?: 'ObfuscatedThemeInstrumentMatchResult';
    isPartialMatch: Scalars['Boolean']['output'];
    isStrongMatch: Scalars['Boolean']['output'];
    matchScore: Scalars['Int']['output'];
};

export type Organization = {
    __typename?: 'Organization';
    aboutUrl?: Maybe<Scalars['String']['output']>;
    accountTier: Scalars['String']['output'];
    analystThemeRequests: Array<AnalystThemeRequest>;
    apiAuthToken?: Maybe<Scalars['String']['output']>;
    avatarUrl?: Maybe<Scalars['String']['output']>;
    canCurrentUserManageUsers: Scalars['Boolean']['output'];
    canCurrentUserUpdateOrganizationProfile: Scalars['Boolean']['output'];
    canUseCryptoInIndexBuilder: Scalars['Boolean']['output'];
    companyEvaluationRequestLimit?: Maybe<Scalars['Int']['output']>;
    companyEvaluationRequests: Array<InstrumentEvaluationRequest>;
    createdAt: Scalars['UTCDateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    indexes: Array<Index>;
    members: Array<User>;
    name: Scalars['String']['output'];
    numberOfAdmins: Scalars['Int']['output'];
    organizationUserInvites: Array<OrganizationUserInvite>;
    organizationUsers: Array<OrganizationUser>;
    owner: Scalars['Int']['output'];
    publicIndexes: Array<Index>;
    remainingAdminSlots: Scalars['Int']['output'];
    researchNotes: Array<ResearchNote>;
    twitterHandle?: Maybe<Scalars['String']['output']>;
};

export type OrganizationOrganizationUserInvitesArgs = {
    excludeAccepted?: InputMaybe<Scalars['Boolean']['input']>;
    pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationUser = {
    __typename?: 'OrganizationUser';
    canCreateIndexes: Scalars['Boolean']['output'];
    createdAt: Scalars['UTCDateTime']['output'];
    id: Scalars['ID']['output'];
    organization: Organization;
    role: OrganizationUserRole;
    user: User;
};

export type OrganizationUserInvite = {
    __typename?: 'OrganizationUserInvite';
    acceptUrl?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['UTCDateTime']['output'];
    createdByUser: User;
    expiresAt: Scalars['UTCDateTime']['output'];
    id: Scalars['ID']['output'];
    inviteEmail: Scalars['String']['output'];
    isAccepted: Scalars['Boolean']['output'];
    isExpired: Scalars['Boolean']['output'];
    isPending: Scalars['Boolean']['output'];
    message?: Maybe<Scalars['String']['output']>;
    organization: Organization;
    role: OrganizationUserRole;
};

export enum OrganizationUserRole {
    Admin = 'admin',
    Analyst = 'analyst',
}

export type PeerFundamentalsEvaluation = {
    __typename?: 'PeerFundamentalsEvaluation';
    analysis: Scalars['String']['output'];
    date: Scalars['Date']['output'];
    id: Scalars['String']['output'];
};

export type PerformanceComparison = {
    __typename?: 'PerformanceComparison';
    endDate: Scalars['Date']['output'];
    results: Array<PerformanceComparisonResult>;
    startDate: Scalars['Date']['output'];
};

export type PerformanceComparisonInput = {
    backtestInputs?: InputMaybe<Array<BacktestInput>>;
    endDate: Scalars['Date']['input'];
    instrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    publicIndexIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    startDate: Scalars['Date']['input'];
    thematicIndexIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PerformanceComparisonResult = {
    analysis?: Maybe<AnalysisFigures>;
    points: Array<DatedValue>;
};

export type PredicateLimit = {
    __typename?: 'PredicateLimit';
    type: LimitType;
    value: Scalars['Int']['output'];
};

export type PredicateOrderSpec = {
    __typename?: 'PredicateOrderSpec';
    limit?: Maybe<PredicateLimit>;
    sort: PredicateSort;
};

export type PredicateSort = {
    __typename?: 'PredicateSort';
    direction: SortDirection;
    field: Field;
};

export enum PredicateType {
    AndPredicate = 'AndPredicate',
    CombinedQueryPredicate = 'CombinedQueryPredicate',
    ComparisonPredicate = 'ComparisonPredicate',
    GlobalContextPredicate = 'GlobalContextPredicate',
    InPredicate = 'InPredicate',
    MatchPredicate = 'MatchPredicate',
    OrPredicate = 'OrPredicate',
    PhraseSimilarityPredicate = 'PhraseSimilarityPredicate',
    SortLimitPredicate = 'SortLimitPredicate',
    StatisticalPredicate = 'StatisticalPredicate',
}

export type PriceHistoryEvaluation = {
    __typename?: 'PriceHistoryEvaluation';
    analysis: Scalars['String']['output'];
    date: Scalars['Date']['output'];
    id: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type PriceWeightedStrategy = BaseStrategy &
    StaticComponentStrategy & {
        __typename?: 'PriceWeightedStrategy';
        /** List of components */
        components: Array<Instrument>;
        emptyField: Scalars['String']['output'];
        numComponents: Scalars['Int']['output'];
    };

export type ProcessIndexAttempt = {
    __typename?: 'ProcessIndexAttempt';
    errorMessage?: Maybe<Scalars['String']['output']>;
    errored?: Maybe<Scalars['Boolean']['output']>;
    finishedAt?: Maybe<Scalars['UTCDateTime']['output']>;
    id: Scalars['ID']['output'];
    lastProcessIndexDate?: Maybe<Scalars['Date']['output']>;
    startProcessIndexDate?: Maybe<Scalars['Date']['output']>;
    startedAt: Scalars['UTCDateTime']['output'];
    succeeded?: Maybe<Scalars['Boolean']['output']>;
    untilProcessIndexDate?: Maybe<Scalars['Date']['output']>;
};

export type Project = {
    __typename?: 'Project';
    createdAt: Scalars['UTCDateTime']['output'];
    createdByUserId: Scalars['ID']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    organizationId: Scalars['ID']['output'];
    parentProjectId?: Maybe<Scalars['ID']['output']>;
    researchNoteIds?: Maybe<Array<Scalars['ID']['output']>>;
    researchNotes: Array<ResearchNote>;
    title: Scalars['String']['output'];
    updatedAt: Scalars['UTCDateTime']['output'];
};

export type PublicIndexResult = PerformanceComparisonResult & {
    __typename?: 'PublicIndexResult';
    analysis?: Maybe<AnalysisFigures>;
    points: Array<DatedValue>;
    publicIndex: BenchmarkIndex;
};

export type QuarterlyFinancials = {
    __typename?: 'QuarterlyFinancials';
    balanceSheet?: Maybe<DataFrame>;
    cashFlowStatement?: Maybe<DataFrame>;
    incomeStatement?: Maybe<DataFrame>;
};

export type Query = {
    __typename?: 'Query';
    allowInstrumentEvaluationRequest: Scalars['Boolean']['output'];
    analystThemeResult: AnalystThemeResult;
    analystThemeSearch: AnalystThemeResult;
    articleStrategy: Array<WeightFromArticle>;
    benchmarkIndexes: Array<BenchmarkIndex>;
    currentUser?: Maybe<User>;
    featuredIndex?: Maybe<Index>;
    index?: Maybe<Index>;
    indexDraft?: Maybe<IndexDraft>;
    indexVersion?: Maybe<IndexVersion>;
    indexes: Array<Index>;
    instrument?: Maybe<Instrument>;
    instrumentAnalysis: InstrumentAnalysis;
    instrumentEvaluationRequest: Scalars['String']['output'];
    instrumentEvaluationRequestCount: Scalars['Int']['output'];
    instrumentEvaluationRequestsRemaining: Scalars['Int']['output'];
    instrumentEvaluationResult: InstrumentEvaluation;
    instrumentLookupBySymbol: Array<Instrument>;
    instrumentSearch: InstrumentSearchResults;
    instruments: Array<Instrument>;
    organization?: Maybe<Organization>;
    organizationPerformanceCsv: Scalars['String']['output'];
    organizationUserInvite?: Maybe<OrganizationUserInvite>;
    organizations: Array<Organization>;
    projects: Array<Project>;
    rankedIndexes: RankedIndexesResultType;
    researchNote?: Maybe<ResearchNote>;
    researchNotes: Array<ResearchNote>;
    sectorsAndIndustries: Array<InstrumentSector>;
    tag?: Maybe<Tag>;
    user?: Maybe<User>;
    userPerformanceCsv: Scalars['String']['output'];
    userSessions: Array<UserSession>;
};

export type QueryAnalystThemeResultArgs = {
    requestId: Scalars['ID']['input'];
};

export type QueryAnalystThemeSearchArgs = {
    userFilter?: InputMaybe<InstrumentSearchFilterInput>;
    userQuery: Scalars['String']['input'];
};

export type QueryArticleStrategyArgs = {
    id: Scalars['ID']['input'];
};

export type QueryIndexArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    symbol?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIndexDraftArgs = {
    builderIndexId: Scalars['ID']['input'];
};

export type QueryIndexVersionArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryIndexesArgs = {
    hasSnapshotsOnly?: InputMaybe<Scalars['Boolean']['input']>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    isBloombergEnabledOnly?: InputMaybe<Scalars['Boolean']['input']>;
    isNotable?: InputMaybe<Scalars['Boolean']['input']>;
    isProcessingDisabledOnly?: InputMaybe<Scalars['Boolean']['input']>;
    symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryInstrumentArgs = {
    id: Scalars['ID']['input'];
};

export type QueryInstrumentAnalysisArgs = {
    instrumentId: Scalars['ID']['input'];
    userQuery: Scalars['String']['input'];
};

export type QueryInstrumentEvaluationRequestArgs = {
    instrumentId: Scalars['ID']['input'];
};

export type QueryInstrumentEvaluationResultArgs = {
    requestId: Scalars['ID']['input'];
};

export type QueryInstrumentLookupBySymbolArgs = {
    input: InstrumentSearchBySymbol;
};

export type QueryInstrumentSearchArgs = {
    input: InstrumentSearchInput;
};

export type QueryInstrumentsArgs = {
    ids: Array<Scalars['ID']['input']>;
};

export type QueryOrganizationArgs = {
    id: Scalars['ID']['input'];
};

export type QueryOrganizationPerformanceCsvArgs = {
    organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrganizationUserInviteArgs = {
    id: Scalars['ID']['input'];
    pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProjectsArgs = {
    createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRankedIndexesArgs = {
    bottomCount?: InputMaybe<Scalars['Int']['input']>;
    minAsOfDate: Scalars['Date']['input'];
    minComponentCount?: InputMaybe<Scalars['Int']['input']>;
    topCount?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryResearchNoteArgs = {
    id: Scalars['ID']['input'];
};

export type QueryResearchNotesArgs = {
    allAnalystRequestIds?: InputMaybe<Scalars['Boolean']['input']>;
    allEvaluationRequestIds?: InputMaybe<Scalars['Boolean']['input']>;
    allIndexIds?: InputMaybe<Scalars['Boolean']['input']>;
    allInstrumentIds?: InputMaybe<Scalars['Boolean']['input']>;
    analystRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    evaluationRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    ids?: InputMaybe<Array<Scalars['ID']['input']>>;
    indexIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    instrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    organizationId?: InputMaybe<Scalars['ID']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTagArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserPerformanceCsvArgs = {
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserSessionsArgs = {
    userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RankedIndexesResultType = {
    __typename?: 'RankedIndexesResultType';
    /** @deprecated Use fields that contain `performance` */
    bottom: Array<Index>;
    /** @deprecated Use fields that contain `performance` */
    top: Array<Index>;
};

export type RemoveOrganizationUserInput = {
    organizationUserId: Scalars['ID']['input'];
};

export type RemoveOrganizationUserResult = {
    __typename?: 'RemoveOrganizationUserResult';
    organization: Organization;
    success: Scalars['Boolean']['output'];
};

export type RemoveProjectInput = {
    projectId: Scalars['ID']['input'];
};

export type RemoveResearchNoteInput = {
    researchNoteId: Scalars['ID']['input'];
};

export type ResearchNote = {
    __typename?: 'ResearchNote';
    analystRequestId?: Maybe<Scalars['ID']['output']>;
    analystThemeField?: Maybe<ResultsModuleType>;
    createdAt: Scalars['UTCDateTime']['output'];
    evaluation?: Maybe<InstrumentEvaluation>;
    evaluationField?: Maybe<CompanyEvaluationModuleType>;
    evaluationRequest?: Maybe<InstrumentEvaluationRequest>;
    evaluationRequestId?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    index?: Maybe<Index>;
    indexId?: Maybe<Scalars['ID']['output']>;
    instrument?: Maybe<Instrument>;
    instrumentField?: Maybe<CompanyInfoSectionGroupKeys>;
    instrumentId?: Maybe<Scalars['ID']['output']>;
    note?: Maybe<Scalars['String']['output']>;
    organizationId: Scalars['ID']['output'];
    title: Scalars['String']['output'];
    updatedAt: Scalars['UTCDateTime']['output'];
    url: Scalars['String']['output'];
    userId: Scalars['ID']['output'];
};

export type ResendLoginAttemptInput = {
    loginAttemptId: Scalars['ID']['input'];
};

export type ResendOrganizationUserInviteInput = {
    inviteId: Scalars['ID']['input'];
};

export type ResendOrganizationUserInviteResult = Error | OrganizationUserInvite;

export enum ResultsModuleType {
    Analysis = 'ANALYSIS',
    DeepDive = 'DEEP_DIVE',
    Filters = 'FILTERS',
    PartialMatches = 'PARTIAL_MATCHES',
    StrongMatches = 'STRONG_MATCHES',
    Subcategories = 'SUBCATEGORIES',
}

export type RootMarketCapWeightedStrategy = BaseStrategy &
    StaticComponentStrategy & {
        __typename?: 'RootMarketCapWeightedStrategy';
        /** List of components */
        components: Array<Instrument>;
        emptyField: Scalars['String']['output'];
        numComponents: Scalars['Int']['output'];
    };

export type RulesBasedEqualWeightedStrategy = BaseStrategy &
    RulesBasedStrategy & {
        __typename?: 'RulesBasedEqualWeightedStrategy';
        /** List of components on date */
        componentsOnDate: Array<Instrument>;
        emptyField: Scalars['String']['output'];
        /** Filter for determining components */
        filter: InstrumentSearchPredicate;
        numComponentsOnDate: Scalars['Int']['output'];
    };

export type RulesBasedEqualWeightedStrategyComponentsOnDateArgs = {
    asOfDate: Scalars['Date']['input'];
};

export type RulesBasedEqualWeightedStrategyNumComponentsOnDateArgs = {
    asOfDate: Scalars['Date']['input'];
};

export type RulesBasedStrategy = {
    componentsOnDate: Array<Instrument>;
    emptyField: Scalars['String']['output'];
    filter: InstrumentSearchPredicate;
    numComponentsOnDate: Scalars['Int']['output'];
};

export type RulesBasedStrategyComponentsOnDateArgs = {
    asOfDate: Scalars['Date']['input'];
};

export type RulesBasedStrategyNumComponentsOnDateArgs = {
    asOfDate: Scalars['Date']['input'];
};

export type SecFilingEvaluation = {
    __typename?: 'SECFilingEvaluation';
    id: Scalars['String']['output'];
    insights: Scalars['String']['output'];
    link?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    summary: Scalars['String']['output'];
};

export type SendLoginAttemptResult = Error | SendLoginAttemptSuccess;

export type SendLoginAttemptSuccess = {
    __typename?: 'SendLoginAttemptSuccess';
    loginAttemptId: Scalars['ID']['output'];
};

export type SetInstrumentCompsRequest = {
    baseInstrumentId: Scalars['ID']['input'];
    compInstrumentIds: Array<Scalars['ID']['input']>;
    organizationId: Scalars['ID']['input'];
};

export type SetInstrumentCompsResult = {
    __typename?: 'SetInstrumentCompsResult';
    success: Scalars['Boolean']['output'];
};

export type SetQuarterlyRebalanceScheduleInput = {
    indexId: Scalars['ID']['input'];
};

export enum SortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type StaticComponentStrategy = {
    components: Array<Instrument>;
    emptyField: Scalars['String']['output'];
    numComponents: Scalars['Int']['output'];
};

export enum StatisticalValue {
    Mean = 'MEAN',
    Median = 'MEDIAN',
    Percentile_25 = 'PERCENTILE_25',
    Percentile_75 = 'PERCENTILE_75',
    Stddev = 'STDDEV',
}

export type SystemVariable = {
    __typename?: 'SystemVariable';
    id: Scalars['ID']['output'];
    key: Scalars['String']['output'];
    updatedAt: Scalars['UTCDateTime']['output'];
    value?: Maybe<Scalars['JSON']['output']>;
};

export type Tag = {
    __typename?: 'Tag';
    bannerUrl?: Maybe<Scalars['String']['output']>;
    categoryName: Scalars['String']['output'];
    createdAt: Scalars['UTCDateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    displayName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    indexes: Array<Index>;
    numIndexes: Scalars['Int']['output'];
    publicIndexes: Array<Index>;
    publicIndexesPage: IndexPage;
    slug: Scalars['String']['output'];
    tagName?: Maybe<Scalars['String']['output']>;
};

export type TagPublicIndexesPageArgs = {
    pagination?: InputMaybe<CursorPaginationInput>;
};

export type ThematicIndexResult = PerformanceComparisonResult & {
    __typename?: 'ThematicIndexResult';
    analysis?: Maybe<AnalysisFigures>;
    points: Array<DatedValue>;
    thematicIndex: Index;
};

export type ThemeInstrumentMatchResult = ThemeInstrumentMatchResultBase & {
    __typename?: 'ThemeInstrumentMatchResult';
    citation?: Maybe<Scalars['String']['output']>;
    instrument: Instrument;
    instrumentId: Scalars['ID']['output'];
    isPartialMatch: Scalars['Boolean']['output'];
    isStrongMatch: Scalars['Boolean']['output'];
    matchScore: Scalars['Int']['output'];
    reason: Scalars['String']['output'];
};

export type ThemeInstrumentMatchResultBase = {
    isPartialMatch: Scalars['Boolean']['output'];
    isStrongMatch: Scalars['Boolean']['output'];
    matchScore: Scalars['Int']['output'];
};

export type ToolCall = {
    __typename?: 'ToolCall';
    args: Scalars['JSON']['output'];
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type ToolResult = {
    __typename?: 'ToolResult';
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type UtcDateTimeRange = {
    __typename?: 'UTCDateTimeRange';
    lower?: Maybe<Scalars['UTCDateTime']['output']>;
    upper?: Maybe<Scalars['UTCDateTime']['output']>;
};

export type UpdateIndexBannerImageInput = {
    imageFile?: InputMaybe<Scalars['Upload']['input']>;
    indexId: Scalars['ID']['input'];
};

export type UpdateIndexBannerImageResult = Error | Index;

export type UpdateIndexIsComponentsPrivateType = {
    indexId: Scalars['ID']['input'];
    isComponentsPrivate: Scalars['Boolean']['input'];
};

export type UpdateIndexIsHiddenType = {
    indexId: Scalars['ID']['input'];
    isHidden: Scalars['Boolean']['input'];
};

export type UpdateOrganizationAvatarImageInput = {
    imageFile: Scalars['Upload']['input'];
    organizationId: Scalars['ID']['input'];
};

export type UpdateOrganizationAvatarImageResult = Error | Organization;

export type UpdateOrganizationProfileInput = {
    aboutUrl?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    organizationId: Scalars['ID']['input'];
    twitterHandle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationProfileResult = FieldErrors | Organization;

export type UpdateOrganizationUserRoleInput = {
    organizationUserId: Scalars['ID']['input'];
    role: OrganizationUserRole;
};

export type UpdateProjectInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    parentProjectId?: InputMaybe<Scalars['ID']['input']>;
    projectId: Scalars['ID']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectResult = FieldErrors | Project;

export type UpdateResearchNoteInput = {
    note?: InputMaybe<Scalars['String']['input']>;
    researchNoteId: Scalars['ID']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateResearchNoteResult = FieldErrors | ResearchNote;

export type UpdateUserAvatarImageInput = {
    imageFile: Scalars['Upload']['input'];
    percolateOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    userId: Scalars['ID']['input'];
};

export type UpdateUserAvatarImageResult = Error | User;

export type UpdateUserProfileInput = {
    about?: InputMaybe<Scalars['String']['input']>;
    facebookHandle?: InputMaybe<Scalars['String']['input']>;
    githubHandle?: InputMaybe<Scalars['String']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    location?: InputMaybe<Scalars['String']['input']>;
    mediumHandle?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    percolateOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    redditHandle?: InputMaybe<Scalars['String']['input']>;
    twitterHandle?: InputMaybe<Scalars['String']['input']>;
    userId: Scalars['ID']['input'];
};

export type UpdateUserProfileResult = FieldErrors | User;

export type UploadIndexOpengraphImageInput = {
    imageFile?: InputMaybe<Scalars['Upload']['input']>;
    indexId: Scalars['ID']['input'];
};

export type UploadIndexOpengraphImageResult = Error | Index;

export type User = {
    __typename?: 'User';
    about?: Maybe<Scalars['String']['output']>;
    analystThemeRequests: Array<AnalystThemeRequest>;
    avatarUrl?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['UTCDateTime']['output'];
    email: Scalars['String']['output'];
    facebookHandle?: Maybe<Scalars['String']['output']>;
    followedIndexes: Array<Index>;
    githubHandle?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isFollowingIndex: Scalars['Boolean']['output'];
    isThematicAdmin: Scalars['Boolean']['output'];
    linkedinUrl?: Maybe<Scalars['String']['output']>;
    location?: Maybe<Scalars['String']['output']>;
    mediumHandle?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    /** @deprecated Use `primaryOrganization` field until multi organization is more fully supported */
    organizationUsers: Array<OrganizationUser>;
    /** @deprecated Use `primaryOrganization` field until multi organization is more fully supported */
    organizations: Array<Organization>;
    primaryOrganization?: Maybe<Organization>;
    redditHandle?: Maybe<Scalars['String']['output']>;
    researchNotes: Array<ResearchNote>;
    twitterHandle?: Maybe<Scalars['String']['output']>;
};

export type UserAnalystThemeRequestsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type UserIsFollowingIndexArgs = {
    indexId: Scalars['ID']['input'];
};

export type UserSession = {
    __typename?: 'UserSession';
    createdAt: Scalars['UTCDateTime']['output'];
    id: Scalars['ID']['output'];
    ipAddress: Scalars['String']['output'];
    sessionExpiresAt: Scalars['UTCDateTime']['output'];
    sessionToken: Scalars['String']['output'];
    user: User;
};

export type WeightFromArticle = {
    __typename?: 'WeightFromArticle';
    putOrCall?: Maybe<Scalars['String']['output']>;
    symbol: Scalars['String']['output'];
    weight: Scalars['Decimal']['output'];
};

export type WeightStrategyInput = {
    customWeightedInstruments?: InputMaybe<Array<WeightedComponentInput>>;
    equalWeightedInstrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    marketCapWeightedInstrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    priceWeightedInstrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    rootMarketCapWeightedInstrumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    ruleBasedEqualWeightedStrategyFilter?: InputMaybe<InstrumentSearchInput>;
};

export type WeightedComponentInput = {
    instrumentId: Scalars['ID']['input'];
    weight?: InputMaybe<Scalars['Decimal']['input']>;
    /** @deprecated Use `weight` field */
    weightPercentage?: InputMaybe<Scalars['Decimal']['input']>;
};

export type WeightedIndexComponent = {
    __typename?: 'WeightedIndexComponent';
    /** Component */
    component: Instrument;
    weight: Scalars['Decimal']['output'];
};
